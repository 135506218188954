/* You can add global styles to this file, and also import other style files */
/* Importing Bootstrap SCSS file. */

/*@import 'bootstrap/scss/bootstrap'; */

/*
@import "bootstrap/scss/mixins/banner";
@include bsBanner("");
*/

// scss-docs-start import-stack
// Configuration
@import 'bootstrap/scss/functions';
@import './assets/scss/bootstrap-variable-overrides';
@import 'bootstrap/scss/variables';
//@import "bootstrap/scss/variables-dark";
@import 'bootstrap/scss/maps';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/utilities';

// Layout & components
@import 'bootstrap/scss/root';
@import 'bootstrap/scss/reboot';
@import 'bootstrap/scss/type';
//@import "bootstrap/scss/images";

@import 'bootstrap/scss/containers';
@import 'bootstrap/scss/grid';
@import 'bootstrap/scss/tables';
@import 'bootstrap/scss/forms';
@import 'bootstrap/scss/buttons';
@import 'bootstrap/scss/transitions';
@import 'bootstrap/scss/dropdown';
@import 'bootstrap/scss/button-group';
@import 'bootstrap/scss/nav';
@import 'bootstrap/scss/navbar';
//@import "bootstrap/scss/card";
@import 'bootstrap/scss/accordion';
@import 'bootstrap/scss/breadcrumb';
@import 'bootstrap/scss/pagination';
@import 'bootstrap/scss/badge';
@import 'bootstrap/scss/alert';
@import 'bootstrap/scss/progress';
@import 'bootstrap/scss/list-group';
@import 'bootstrap/scss/close';
@import 'bootstrap/scss/toasts';
@import 'bootstrap/scss/modal';
@import 'bootstrap/scss/tooltip';
@import 'bootstrap/scss/popover';
//@import "bootstrap/scss/carousel";
@import 'bootstrap/scss/spinners';
@import 'bootstrap/scss/offcanvas';
@import 'bootstrap/scss/placeholders';

// Helpers
@import 'bootstrap/scss/helpers';

// Utilities
@import 'bootstrap/scss/utilities/api';
// scss-docs-end import-stack

//DS Procempa
@import './assets/scss/tokens';
@import './assets/scss/colors';
@import './assets/scss/components.scss';

//FICAI custom
@import './assets/scss/utilities';
@import './assets/scss/global.scss';

$theme-colors: map-merge($theme-colors, $custom-colors);

:root {
  --cor-erro: #d93855;
  --font-size-scale-base: 1rem;
  --body-text-color: var(--color-neutral-body);
  --bs-body-color: var(--body-text-color);
}

hr {
  opacity: unset;
}

.main {
  padding: var(--font-size-scale-down-03) var(--font-size-scale-up-03)
    var(--font-size-scale-up-03);
  flex: 1;
  max-width: 100vw;
}

app-nav-bar {
  background-color: var(--color-neutral-background);
  @include media-breakpoint-up(md) {
    border-right: 1px solid var(--color-neutral-light);
    box-shadow: 4px 0 0 var(--color-neutral-background);
  }
}

p {
  max-width: 80ch;
}

h1,
.h1 {
  small {
    font-weight: var(--font-weight-medium);
    font-size: 0.75em;
  }
}

a {
  text-decoration: none;
}

a:not([href]):not([class]) {
  color: var(--color-primary-interactive-area);
  &:hover {
    color: var(--color-primary-dark);
  }
}

.page-header {
  margin-top: var(--font-size-scale-up-02);
  padding-bottom: var(--font-size-scale-up-01);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--font-size-scale-base);
}

.section-header {
  display: flex;
  gap: 0.5rem;
  align-items: baseline;
}

.block {
  margin-bottom: var(--font-size-scale-up-03);
}

.card {
  padding: var(--font-size-scale-base);
}

.lead {
  font-size: var(--font-size-scale-up-01);
}

ul.nostyle {
  padding-left: unset;
  list-style-type: none;
}

ul.bolinha {
  list-style-type: disc;
}

//Formulários
.form-group {
  margin-bottom: var(--font-size-scale-up-01);
}

.form-label {
  font-weight: var(--font-weight-bold);
  color: var(--color-primary-headings);
  font-size: var(--font-size-scale-base);
}

.required {
  &:after {
    display: inline-block;
    content: '*';
    color: var(--color-danger-base);
    font-weight: initial;
    margin-left: 0.125rem;
  }
}

.form-control,
.form-select {
  border-color: var(--color-neutral-muted);
  padding: var(--font-size-scale-down-04) var(--font-size-scale-down-04)
    var(--font-size-scale-down-04) var(--font-size-scale-down-02);
}

.form-select {
  padding-right: var(--font-size-scale-up-03);
  overflow: hidden;
  text-wrap: nowrap;
  text-overflow: ellipsis;
}

.input-group {
  > .form-control + .br-button {
    margin-left: -1px;
    z-index: 1;
    border: 1px solid var(--color-neutral-muted);
    border-left: 0 none;
    //background-color: var(--color-white);
    &.primary {
      color: var(--color-primary-interactive-area);
      &:hover {
        border: unset;
        background-color: var(--color-primary-interactive-area);
        color: var(--color-white);
        border: 1px solid var(--color-primary-interactive-area);
        border-left: 0 none;
      }
    }
  }
}

legend {
  font-size: var(--font-size-scale-up-01);
  color: var(--color-primary-headings);
  margin-bottom: var(--font-size-scale-base);
}

fieldset fieldset {
  margin-bottom: var(--font-size-scale-base);
  legend {
    margin-bottom: calc(var(--font-size-scale-base) / 2);
    font-size: var(--font-size-scale-down-01);
    text-transform: uppercase;
    font-weight: var(--font-weight-bold);
    letter-spacing: 0.075rem;
    &.form-label {
      text-transform: unset;
      letter-spacing: unset;
    }
  }
}

.helper {
  font-size: var(--font-size-scale-down-01);
  color: var(--color-neutral-dark);
  .form-label + & {
    display: block;
    margin-top: -0.25rem;
    margin-bottom: var(--font-size-scale-down-04);
  }
}

.br-pagination select {
  border: 0 none;
  min-width: 5rem;
  padding: 0 1rem;
}

.botoes {
  display: flex;
  gap: var(--font-size-scale-base);
  flex-wrap: wrap;
}

.observacoes {
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 5;
}

//Revisar/remover este item
.form-check {
  margin-top: 2.75rem;
}

//Revisar/remover este item
.check-situacao {
  margin-top: 7px;
}

//Estilos paginas de edição
.data-view {
  p {
    margin-bottom: var(--font-size-scale-down-04);
    display: flex;
    flex-wrap: wrap;
    column-gap: 0.5rem;
    align-items: baseline;
  }
}

.container-edit {
  background-color: var(--color-neutral-background);
  padding: var(--font-size-scale-base);
  fieldset:last-of-type {
    margin-bottom: unset;
  }
}

//Revisar/remover este item
.title-diagnostico {
  text-transform: uppercase;
  color: var(--color-primary-headings);
  font-weight: bold;
  font-size: 14px;
  margin-top: 10px;
}

//O código abaixo ainda não foi revisado.
.tabs-container {
  // background: #eee;
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 30px;
  margin-top: -10px;
  height: 40px;
  border-bottom: 1px solid #ccc;
  width: 100%;
}

.tabs-container .tab-link {
  width: 33, 33%;
  position: relative;
  height: 100%;
  padding: 10px;
  float: left;
  cursor: pointer;
  color: $primary-darker;
  font-weight: 500;
}

.tab-link:hover {
  border: 1px solid #6495ed;
  color: #6495ed;
}

.tab-link::after {
  position: absolute;
  left: 4px;
  right: 4px;
  bottom: 0;
  display: block;
  content: '';
  border-bottom: 2px solid #6495ed;
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
}

.active::after {
  transform: scaleX(1);
}

.active.tab-link {
  font-weight: bold;
  color: $primary;
}

.link-encaminhar {
  font-weight: bold;
  color: #6495ed;
  text-decoration: none;
  cursor: pointer;
}

input[type='file'] {
  display: none;
}

.card-file {
  display: block;
  width: 100%;
  margin-bottom: var(--font-size-scale-down-02);
  border-radius: 0%;
  border: 1px solid var(--color-neutral-light);
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  gap: var(--spacing-scale-base);
  .br-button {
    flex: 1;
    text-wrap: nowrap;
    overflow: hidden;
    span {
      text-wrap: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
.file-info {
  display: flex;
  align-items: baseline;
  gap: var(--spacing-scale-base);
}
.body-file {
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.label {
  color: var(--color-primary-headings);
  font-weight: bold;
}

.link-action {
  cursor: pointer;
  color: $primary;
}

.perfil-title,
.local {
  color: var(--color-primary-headings);
  font-weight: bold;
  margin-right: 10px;
}

.container-edit legend {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.resumo {
  // max-width: 30rem;
  word-wrap: break-word;
}

//Bootstrap overrides
.table {
  --bs-table-color: var(--body-text-color);
}

.table > :not(caption) > * > * {
  padding: var(--font-size-scale-base);
  color: var(--body-text-color);
  //background-color: var(--col);
  //border-bottom-width: var(--bs-border-width);
  //box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));
}
.table > thead > tr > th {
  border-bottom: 0.125rem solid var(--color-highlight-muted);
}

.form-control {
  &[readonly] {
    background-color: var(--color-neutral-background);
  }
}

.has-success {
  border: 2px solid rgb(26, 180, 26);
}

.has-error {
  border: 2px solid var(--cor-erro);
}

.msg-error {
  font-size: var(--font-size-scale-down-01);
  color: var(--color-danger-dark);
  fa-icon {
    color: var(--color-danger-base);
    margin-right: 0.25rem;
  }
}

.breadcrumb-item {
  text-transform: uppercase;
  font-size: var(--font-size-scale-down-02);
  color: var(--color-neutral-base);
  a {
    font-weight: var(--font-weight-bold);
  }
}

//overrides gov.br
.br-button {
  display: flex;
  &.is-small {
    padding: var(--font-size-scale-down-03) var(--font-size-scale-base);
  }
  &.is-tertiary {
    background-color: transparent;
  }
}
.br-tag {
  --surface-rounder-lg: 0.25rem;
  //text-transform: unset;
  font-weight: 600;
  letter-spacing: 0.05rem;
  > .ng-fa-icon:first-child {
    margin-right: var(--spacing-scale-half);
  }
  &.is-secondary {
    background-color: var(--color-neutral-base);
    color: var(--color-white);
  }
  &.is-muted {
    background-color: var(--color-neutral-background);
    color: var(--color-neutral-dark);
  }
}

.br-radio {
  input:disabled {
    --border-color: var(--color-neutral-muted);
    --proc-accent-color: var(--border-color);
    cursor: not-allowed;
    + label {
      color: var(--color-neutral-muted);
      cursor: not-allowed;
    }
    &:checked {
      --border-color: var(--color-neutral-muted);
      + label {
        color: var(--color-neutral-muted);
        cursor: not-allowed;
      }
    }
  }
}

.radios-inline {
  display: inline-flex;
  column-gap: var(--font-size-scale-up-03);
  row-gap: var(--font-size-scale-down-04);
  flex-wrap: wrap;
  .br-radio + .br-radio {
    margin-top: unset;
  }
}

//Overrides componente modal (SweetAlert)
.swal2-container {
  &.swal2-backdrop-show {
    background: rgba(var(--color-primary-headings-rgb), 0.6);
  }
  .swal2-popup {
    border-radius: unset;
    padding: var(--spacing-scale-4x) var(--spacing-scale-5x);
  }
  .swal2-icon {
    margin-top: var(--spacing-scale-2x);
  }
  .swal2-error {
    border-color: var(--color-danger-base);
    color: var(--color-danger-base);
  }
  button {
    --button-radius: 0;
    --button-border-width: 0.125rem;
    --proc-status-dark: transparent;
    --button-border-color: var(--proc-status-dark);
    --button-hover-border-color: var(--button-border-color);
    --proc-button-color: var(--pure-0);
    --proc-status-hover-color: var(--color-primary-dark);
    --proc-status-active-bgcolor: var(--color-primary-background);
    --proc-status-focus-color: var(--proc-button-color);
    padding: var(--spacing-scale-baseh) var(--spacing-scale-2x);
    font-size: var(--font-size-scale-base);
    gap: var(--spacing-scale-base);
    border: var(--button-border-width) solid var(--button-border-color);
    font-weight: var(--font-weight-bold);
    border-radius: unset !important;
    &:focus,
    &:focus-visible {
      outline: var(--color-highlight-focus) solid 4px;
    }
  }
  .swal2-confirm {
    background-color: var(--color-primary-interactive-area);
    &:hover {
      border-color: var(--color-primary-dark);
    }
  }
  .swal2-deny {
    background-color: var(--color-danger-base);
  }


  // .swal2-cancel {
  //   background-color: transparent;
  //   color: var(--color-primary-interactive-area);
  //   &:hover {
  //     background-image: unset !important;
  //     background-color: transparent;
  //     border-color: var(--blue-10);
  //     // color: var(--color-primary-dark);
  //     color: red;
  //   }
  // }

  .styleTitle{
    font-size: 25px;
  }
}

.button-outline{
  padding: var(--spacing-scale-baseh) var(--spacing-scale-2x);
  font-size: var(--font-size-scale-base);
  gap: var(--spacing-scale-base);
  border: 1px solid var(--color-primary-interactive-area);
  font-weight: var(--font-weight-bold);
  border-radius: unset !important;
  color: var(--color-primary-interactive-area)
  ;
  background-color: #ffffff;
}

//Utilities
.divider-bottom {
  border-bottom: 1px solid var(--color-neutral-light);
  box-shadow: 0 3px 0 0 var(--color-neutral-background);
}

.texto-secundario {
  color: var(--color-neutral-base);
}

.shadow-highlight {
  border: 1px solid var(--color-highlight-focus);
  box-shadow: 0.25rem 0.25rem var(--color-highlight-focus);
}

.ml-auto {
  margin-left: auto;
}

.img-responsive {
  max-width: 100%;
}

.back{
  cursor: pointer;
}

.notification{
  display: flex;
  flex-direction: row;
}

.icon-notification{
  margin-right: 10px;
}

//
