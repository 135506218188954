
/* Componentes do desig system Procempa, baseados no gov.br - versão obtida em 04/10/2023 em https://git.procempa.com.br/design/design-system/*/
/*
* {
    transition: 0.2s ease-in-out;
}
*/

input:focus-visible {
    outline-color: var(--focus);
    outline-width: var(--spacing-scale-half);
    outline-offset: 2px;
}

/* correção bug lazy-loading flutuando por cima do header */
[loading] {
    z-index: unset;
}

/* button */
.br-button {
    --button-radius: 0;
    --button-border-width: .125rem;
    --proc-status-dark: transparent;
    --button-border-color: var(--proc-status-dark);
    --button-hover-border-color: var(--button-border-color);
    --proc-button-color: var(--pure-0);
    --proc-status-hover-color: var(--color-primary-dark);
    --proc-status-active-bgcolor: var(--color-primary-background);
    --proc-status-focus-color: var(--proc-button-color);
    padding: var(--spacing-scale-baseh) var(--spacing-scale-2x);
    font-size: var(--font-size-scale-base);
    gap: var(--spacing-scale-base);
    border: var(--button-border-width) solid var(--button-border-color);
    font-weight: var(--font-weight-bold);
}

.br-button:not(:disabled) {
    --focus-offset: 0;
}

.br-button:not(:disabled):not(:disabled):hover {
    background-image: none;
    border-color: var(--button-hover-border-color);
    color: var(--proc-status-hover-color);
}

.br-button:not(:disabled):not(:disabled):active {
    --interactive-rgb: var(--proc-status-active-bgcolor);
    background-color: var(--interactive-rgb);
    color: var(--proc-status-hover-color);
}

.br-button:not(:disabled):not(:disabled):focus-visible {
    outline: var(--color-highlight-focus) solid 4px;
}

.br-button:disabled {
  opacity: .6;
  cursor: not-allowed;
}

.br-button.is-small,
.br-button.small,
.br-button[small] {
  font-size: var(--font-size-scale-down-01);
}

.br-button.is-primary,
.br-button.primary,
.br-button[primary] {
    --button-hover-border-color: var(--color-primary-dark);
    --proc-status-hover-color: var(--color-dark);
    --proc-status-active-bgcolor: var(--proc-status-dark);
    background-color: var(--interactive-light);
    color: var(--proc-button-color);
}

.br-button.is-secondary,
.br-button.secondary,
.br-button[secondary] {
  --button-border-color: var(--color-primary-surfaces);
  --button-hover-border-color:var(--color-primary-surfaces);
    //--proc-status-dark: var(--color-neutral-light);
    --proc-status-hover-color: var(--color-primary-dark);
    --proc-status-active-bgcolor: var(--blue-10);
    --proc-button-color: var(--interactive-light);
    background-color: var(--proc-status-dark);
    color: var(--proc-button-color);
}

.br-button.is-tertiary,
.br-button.tertiary,
.br-button[secondary] {
   // --proc-status-dark: var(--color-neutral-light);
    --proc-status-hover-color: var(--color-primary-dark);
    --proc-status-active-bgcolor: var(--blue-10);
    --button-hover-border-color: var(--blue-10);
    --proc-button-color: var(--interactive-light);
    background-color: var(--color-white);
    //border: var(--spacing-scale-half) solid transparent;
    color: var(--interactive-light);
}

.br-button.danger,
.br-button.is-danger,
.br-button[danger] {
    --proc-status-dark: var(--color-danger-dark);
    --proc-status-active-bgcolor: var(--color-danger-background);
}

.br-button.danger.primary,
.br-button.is-danger.primary,
.br-button[danger].primary,
.br-button.danger.is-primary,
.br-button.is-danger.is-primary,
.br-button[danger].is-primary,
.br-button.danger[primary],
.br-button.is-danger[primary],
.br-button[danger][primary]
 {
    --proc-status-dark: var(--color-danger-dark);
    --proc-status-hover-color: var(--color-dark);
    --proc-status-active-bgcolor: var(--proc-status-dark);

    background-color: var(--danger);
    color: var(--color-dark);
}

.br-button.danger.secondary,
.br-button.is-danger.secondary,
.br-button[danger].secondary,
.br-button.danger.is-secondary,
.br-button.is-danger.is-secondary,
.br-button[danger].is-secondary,
.br-button.danger[secondary],
.br-button.is-danger[secondary],
.br-button[danger][secondary]
 {
    --proc-status-dark: var(--color-neutral-light);
    --proc-status-hover-color: var(--color-danger-dark);
    --proc-status-active-bgcolor: var(--proc-status-dark);
    color: var(--danger);
    background-color: var(--color-neutral-background);
}

.br-button.danger,
.br-button.is-danger,
.br-button[danger] {
    --proc-status-dark: transparent; 
    --proc-status-hover-color: var(--color-danger-dark);
    background-color: transparent;
    color: var(--danger);
}

/* 
.br-button.is-warning,
.br-button.warning,
.br-button[warning] {
    --proc-status-dark: var(--color-warning-dark);
    --proc-status-hover-color: var(--color);
    color: var(--color);
}

.br-button.is-success,
.br-button.success,
.br-button[success] {
    --proc-status-dark: var(--color-success-dark);
    --proc-status-hover-color: var(--color-dark);
    color: var(--color-dark);
} */

/* desativa os botões warning, success e info (não existem no nosso DS) */
.br-button.is-warning,
.br-button.warning,
.br-button[warning],
.br-button.is-success,
.br-button.success,
.br-button[success],
.br-button.info,
.br-button.is-info,
.br-button[info] {
    --proc-status-dark: transparent;
    --proc-status-hover-color: var(--color-primary-dark);
    border: var(--spacing-scale-half) solid transparent;
    background-color: transparent;
    color: var(--color-primary-interactive-area);
}

/* checkbox */
.br-checkbox {
    //--checkbox-padding: var(--spacing-scale-base);
    --checkbox-size: var(--font-size-scale-base);
    display: flex;
  }
  /*
  .br-checkbox + .br-checkbox {
    margin-top: var(--spacing-scale-base);
  }
  */
  .br-checkbox input {
    margin: 0;
    opacity: 0;
    position: absolute;
  }
  .br-checkbox input + label {
    cursor: pointer;
    display: inline-block;
    margin-bottom: 0;
    min-height: var(--checkbox-size);
    min-width: var(--checkbox-size);
    padding-left: calc(var(--font-size-scale-base)/2 + var(--checkbox-size));
    position: relative;
  }
  .br-checkbox input + label::before {
    background: var(--color-white);
    border: 1px solid var(--color-neutral-dark);
    border-radius: .125rem;
    content: "";
    height: var(--checkbox-size);
    left: 0;
    position: absolute;
    top: .25rem;
    width: var(--checkbox-size);
  }
  .br-checkbox input + label:empty {
    padding: 0;
  }
  /*
  .br-checkbox label {
    font-weight: var(--font-weight-regular);
  }
  
  .br-checkbox.hidden-label label {
    padding-left: calc(var(--checkbox-size) + var(--surface-width-md) * 2);
    text-indent: -10000px;
    white-space: nowrap;
    width: 0;
  }
  */

  .br-checkbox input:checked + label::before {
    background: var(--color-primary-interactive-area);
    border: 1px solid var(--color-primary-interactive-area);
    border-radius: .125rem;
    content: "";
    height: var(--checkbox-size);
    left: 0;
    position: absolute;
    width: var(--checkbox-size);
  }

  .br-checkbox input:checked + label::after {
    --checkbox-border-width: calc(var(--checkbox-size)/8);
    border: solid var(--color-white);
    border-width: 0 var(--checkbox-border-width) var(--checkbox-border-width) 0;
    content: "";
    height: calc(var(--checkbox-size)/2 + var(--checkbox-size)/6);
    left: calc(var(--checkbox-size)/3);
    position: absolute;
    top: calc(var(--checkbox-size)/3);
    transform: rotate(45deg);
    width: calc(var(--checkbox-size)/3);
  }
  
  .br-checkbox input[indeterminate] + label::before {
    --interactive-rgb: var(--color-rgb);
    background: var(--selected);
    border-color: var(--selected);
  }
  .br-checkbox input[indeterminate]:hover:not(:disabled) + label::before {
    --interactive-rgb: var(--color-dark-rgb);
  }
  .br-checkbox input[indeterminate]:checked + label::after {
    border-color: var(--background-light);
    border-width: 0 0 3px;
    top: 2px;
    transform: none;
  }
  .br-checkbox.is-invalid input + label::before, .br-checkbox.invalid input + label::before, .br-checkbox[invalid] input + label::before {
    --border-color: var(--danger);
  }
  .br-checkbox.is-invalid input:focus-visible:checked + label::before,
  .br-checkbox.is-invalid input:focus-visible + label::before, .br-checkbox.is-invalid input.focus-visible:checked + label::before,
  .br-checkbox.is-invalid input.focus-visible + label::before, .br-checkbox.invalid input:focus-visible:checked + label::before,
  .br-checkbox.invalid input:focus-visible + label::before, .br-checkbox.invalid input.focus-visible:checked + label::before,
  .br-checkbox.invalid input.focus-visible + label::before, .br-checkbox[invalid] input:focus-visible:checked + label::before,
  .br-checkbox[invalid] input:focus-visible + label::before, .br-checkbox[invalid] input.focus-visible:checked + label::before,
  .br-checkbox[invalid] input.focus-visible + label::before {
    --border-color: var(--focus-color);
  }
  .br-checkbox.is-invalid input:checked + label::before, .br-checkbox.invalid input:checked + label::before, .br-checkbox[invalid] input:checked + label::before {
    --border-color: var(--danger);
  }
  .br-checkbox.is-valid input + label::before, .br-checkbox.valid input + label::before, .br-checkbox[valid] input + label::before {
    --border-color: var(--success);
  }
  .br-checkbox.is-valid input:focus-visible:checked + label::before,
  .br-checkbox.is-valid input:focus-visible + label::before, .br-checkbox.is-valid input.focus-visible:checked + label::before,
  .br-checkbox.is-valid input.focus-visible + label::before, .br-checkbox.valid input:focus-visible:checked + label::before,
  .br-checkbox.valid input:focus-visible + label::before, .br-checkbox.valid input.focus-visible:checked + label::before,
  .br-checkbox.valid input.focus-visible + label::before, .br-checkbox[valid] input:focus-visible:checked + label::before,
  .br-checkbox[valid] input:focus-visible + label::before, .br-checkbox[valid] input.focus-visible:checked + label::before,
  .br-checkbox[valid] input.focus-visible + label::before {
    --border-color: var(--focus-color);
  }
  .br-checkbox.is-valid input:checked + label::before, .br-checkbox.valid input:checked + label::before, .br-checkbox[valid] input:checked + label::before {
    --border-color: var(--success);
  }
  .br-checkbox.is-small input + label, .br-checkbox.small input + label, .br-checkbox[small] input + label {
    line-height: var(--spacing-scale-2xh);
    min-height: var(--spacing-scale-2xh);
  }
  .br-checkbox.is-small input + label::before, .br-checkbox.small input + label::before, .br-checkbox[small] input + label::before {
    height: var(--spacing-scale-2xh);
    width: var(--spacing-scale-2xh);
  }
  .br-checkbox.is-small input:checked + label::after, .br-checkbox.small input:checked + label::after, .br-checkbox[small] input:checked + label::after {
    border-width: 0 2px 2px 0;
    height: var(--icon-size-sm);
    left: 7px;
    top: 6px;
    width: 6px;
  }
  .br-checkbox.is-small input:checked[indeterminate] + label::after, .br-checkbox.small input:checked[indeterminate] + label::after, .br-checkbox[small] input:checked[indeterminate] + label::after {
    border-color: var(--background-light);
    border-width: 0 0 3px;
    top: 2px;
    transform: none;
  }
  .br-checkbox input:invalid + label::before {
    --border-color: var(--danger);
  }
  .br-checkbox input:focus-visible:checked + label::before,
  .br-checkbox input:focus-visible + label::before, .br-checkbox input.focus-visible:checked + label::before,
  .br-checkbox input.focus-visible + label::before {
    border-color: var(--focus) !important;
    box-shadow: 0 0 0 var(--surface-width-md) var(--focus);
    outline: none;
  }
  .br-checkbox input:hover:not(:disabled) + label::before {
    --interactive-rgb: var(--interactive-light-rgb);
    background-image: linear-gradient(rgba(var(--interactive-rgb), var(--hover)), rgba(var(--interactive-rgb), var(--hover)));
  }
  .br-checkbox:not(.disabled) input:disabled + label {
    cursor: not-allowed;
    opacity: var(--disabled);
  }
  .br-checkbox:not(.disabled) input:disabled + label * {
    pointer-events: none;
  }
  

 /* switch */ 

 .br-switch {
  --switch-height: var(--switch-height-medium);
  --switch-width: var(--switch-width-medium);
  --switch-toggle-size: var(--switch-toggle-medium);
  --switch-icon-size: var(--switch-icon-medium);
  --switch-height-medium: 1.5rem;
  --switch-width-medium: 2.75rem;
  --switch-toggle-medium: 1.125rem;
  --switch-icon-medium: .5rem;
  display: inline-flex;
  min-height: var(--switch-height);
  /* stylelint-disable no-descending-specificity */
  /* stylelint-enable no-descending-specificity */
} 
.br-switch input {
  appearance: none;
  height: 0;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 0;
}
.br-switch input + label {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  margin-bottom: 0;
  position: relative;
}
.br-switch input + label::before {
  background: var(--color-white);
  border: 1px solid var(--color-neutral-muted);
  border-radius: 100em;
  content: "";
  height: var(--switch-height);
  position: absolute;
  width: var(--switch-width);
}
.br-switch input + label::after {
  background-color: var(--color-neutral-base);
  border-radius: 50%;
  content: "";
  height: var(--switch-toggle-size);
  position: absolute;
  transition: all 0.3s ease-in-out;
  width: var(--switch-toggle-size);
}
.br-switch input:checked + label::before {
  background-color: var(--color-primary-interactive-area);
  border-color: transparent;
}
.br-switch input:checked + label::after {
  background-color: var(--color-white);
}
.br-switch input + label {
  padding-left: calc(.5rem + var(--switch-width));
}
.br-switch input + label:empty {
  padding-left: var(--switch-width);
}
.br-switch input + label::before {
  left: 0;
}
.br-switch input + label::after {
  left: .25rem;
}
.br-switch input:checked + label::after {
  left: calc(.25rem + var(--switch-toggle-size));
}
.br-switch.icon input + label::after {
  align-items: center;
  display: inline-flex;
  background:  var(--color-neutral-base) url(../images/icon-switch-off.svg) no-repeat center;
  justify-content: center;
}
.br-switch.icon input:checked + label::after {
  background:  var(--color-white) url(../images/icon-switch-on.svg) no-repeat center;
}
.br-switch input:focus-visible + label::before,
.br-switch input.focus-visible + label::before {
  border-color: var(--focus) !important;
  box-shadow: 0 0 0 var(--spacing-scale-half) var(--focus);
  outline: none;
}
.br-switch input:hover:not([disabled]):checked + label::before {
  background-image: linear-gradient(rgba(var(--on-rgb), var(--hover)), rgba(var(--on-rgb), var(--hover)));
}
.br-switch input:hover:not([disabled]):not(:checked) + label::before {
  background-image: linear-gradient(rgba(var(--off-rgb), var(--hover)), rgba(var(--off-rgb), var(--hover)));
}
.br-switch input:active:not([disabled]):checked + label::before {
  background-image: linear-gradient(rgba(var(--on-rgb), var(--pressed)), rgba(var(--on-rgb), var(--pressed)));
}
.br-switch input:active:not([disabled]):not(:checked) + label::before {
  background-image: linear-gradient(rgba(var(--off-rgb), var(--pressed)), rgba(var(--off-rgb), var(--pressed)));
} 

.br-switch input:disabled {
  + label {
    color: var(--color-neutral-muted);
    &:after {
      background-color: var(--color-neutral-muted);
    }
  }
}

.br-switch.icon input:disabled {
  + label {
    &:after {
      background: var(--color-neutral-muted) url(../images/icon-switch-off.svg) no-repeat center;
    }
  }
}

.br-switch input:disabled:checked + label::before {
  background-color: var(--color-primary-muted);
}

.br-switch.icon input:disabled:checked {
  + label {
    &:after {
      background: var(--color-white) url(../images/icon-switch-on-disabled.svg) no-repeat center;
    }
  }
}



/* radio */
.br-radio {
    --radio-size: var(--font-size-scale-base);
    display: flex;
    gap:  var(--spacing-scale-half);
    align-items: center;
}

.br-radio+.br-radio {
    margin-top: var(--spacing-scale-base)
}

.br-radio input {
    opacity: unset;
    position: unset;
    appearance: none;
    cursor: pointer;
    border-radius: 50%;
    width: var(--radio-size);
    height: var(--radio-size);
    border: 2px solid var(--border-color);
    background: var(--background);
    margin-bottom: 0;
}

.br-radio input:is(:hover, :checked) {
    border-color: var(--proc-accent-color);
}

.br-radio input:checked {
    --proc-accent-color: var(--selected);
    box-shadow: inset 0 0 0 2px var(--background);
    background-color: var(--proc-accent-color);
}

.br-radio input+label {
    cursor: pointer;
    display: unset;
    margin-bottom: unset;
    min-height: unset;
    min-width: unset;
    padding-left: unset;
    position: unset;
}

.br-radio input+label:before {
    display: none;
}

.br-radio input:checked+label:after {
    display: none;
}

.br-radio.is-valid input,
.br-radio.valid input,
.br-radio[valid] input {
    border-color: var(--proc-accent-color);
    --proc-accent-color: var(--success);
}

.br-radio.invalid input,
.br-radio.is-invalid input,
.br-radio[invalid] input {
    border-color: var(--proc-accent-color);
    --proc-accent-color: var(--danger);
}

.br-radio input:hover {
    --proc-accent-color: var(--color-highlight-focus);
}


/* Paginação */
.br-pagination {
    --pagination-margin: var(--spacing-scale-base);
    --pagination-select-width: 88px;
    --pagination-size: var(--pagination-medium);
    --pagination-small: 24px;
    --pagination-medium: 32px;
    --pagination-large: 40px;
    color: var(--color);
    display: flex;
    flex-wrap: wrap;
    font-weight: var(--font-weight);
    justify-content: center;
  }
  /*
  .br-pagination ul {
    align-items: center;
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .br-pagination li {
    padding: 0;
  }
  
  .br-pagination .page {
    align-items: center;
    border: 1px solid transparent;
    border-radius: 100em;
    color: var(--interactive);
    display: inline-flex;
    justify-content: center;
    margin: 0 calc(var(--pagination-margin) * 0.5);
    min-height: var(--pagination-size);
    min-width: var(--pagination-size);
    padding: 0 var(--spacing-scale-base);
    white-space: nowrap;
  }

  .br-pagination .page:not(:disabled):hover {
    background-image: linear-gradient(rgba(var(--interactive-rgb), var(--hover)), rgba(var(--interactive-rgb), var(--hover)));
  }
  .br-pagination .page.active {
    background: var(--active);
    color: var(--color-dark);
    font-weight: var(--font-weight-semi-bold);
  }
  .br-pagination .pagination-ellipsis {
    margin: 0 calc(var(--pagination-margin) * 0.5);
    position: relative;
  }
  .br-pagination .pagination-ellipsis .br-button {
    --button-size: var(--pagination-size);
  }
  .br-pagination .pagination-ellipsis .br-list {
    max-height: 220px;
    min-width: 4em;
    overflow-y: auto;
    z-index: var(--z-index-layer-1);
  }
  .br-pagination .pagination-ellipsis .br-list::-webkit-scrollbar {
    height: var(--spacing-scale-base);
    width: var(--spacing-scale-base);
  }
  .br-pagination .pagination-ellipsis .br-list::-webkit-scrollbar-track {
    background: var(--gray-10);
  }
  .br-pagination .pagination-ellipsis .br-list::-webkit-scrollbar-thumb {
    background: var(--gray-30);
  }
  .br-pagination .pagination-ellipsis .br-list:hover::-webkit-scrollbar-thumb {
    background: var(--gray-40);
  }
  .br-pagination.small {
    --pagination-size: var(--pagination-small);
  }
  .br-pagination.medium {
    --pagination-size: var(--pagination-medium);
  }
  .br-pagination.large {
    --pagination-size: var(--pagination-large);
  }
  */
  .br-pagination .pagination-information {
    align-items: center;
    display: flex;
  }
  .br-pagination .pagination-per-page,
  .br-pagination .pagination-go-to-page {
    align-items: center;
    display: flex;
  }

  .br-pagination .pagination-per-page .br-input,
  .br-pagination .pagination-go-to-page .br-input {
    align-items: center;
    display: flex;
  }
  
  .br-pagination .pagination-per-page .br-input label,
  .br-pagination .pagination-go-to-page .br-input label {
    font-weight: var(--font-weight-regular);
    margin-bottom: 0;
    margin-right: var(--spacing-scale-base);
    padding-bottom: 0;
  }

  .br-pagination .pagination-per-page .br-input input,
  .br-pagination .pagination-go-to-page .br-input input {
    --input-size: var(--pagination-medium);
    padding-left: var(--spacing-scale-base);
    padding-right: calc(var(--spacing-scale-baseh) + 32px);
    text-align: right;
    width: var(--pagination-select-width);
  }
  .br-pagination .pagination-per-page .br-input input:not(:focus),
  .br-pagination .pagination-go-to-page .br-input input:not(:focus) {
    border-color: transparent;
  }
  .br-pagination .pagination-per-page .br-list,
  .br-pagination .pagination-go-to-page .br-list {
    min-width: 5em;
    right: 0;
    width: auto;
  }
  .br-pagination .pagination-arrows {
    align-items: center;
    display: flex;
  }
  .br-pagination .br-select .br-input .br-button {
    bottom: auto;
    margin-top: 0;
    position: absolute;
    right: var(--spacing-scale-half);
    top: auto;
    transform: none;
  }
  .br-pagination .br-divider {
    border-right-width: var(--divider-size);
    border-top: 0;
    height: 100%;
  }
  /*
  .br-pagination.inverted, .br-pagination.dark-mode {
    --color: var(--color-dark);
    --color-rgb: var(--color-dark-rgb);
    --text-color: var(--color);
    --interactive: var(--interactive-dark);
    --interactive-rgb: var(--interactive-dark-rgb);
    --visited: var(--visited-dark);
    --hover: var(--hover-dark);
    --pressed: var(--pressed-dark);
    --focus-color: var(--focus-color-dark);
    --focus: var(--focus-color);
  }
  .br-pagination.inverted.inverted .br-input,
  .br-pagination.inverted.inverted .br-input label, .br-pagination.inverted.dark-mode .br-input,
  .br-pagination.inverted.dark-mode .br-input label, .br-pagination.dark-mode.inverted .br-input,
  .br-pagination.dark-mode.inverted .br-input label, .br-pagination.dark-mode.dark-mode .br-input,
  .br-pagination.dark-mode.dark-mode .br-input label {
    --color: var(--color-dark);
    --focus-color: var(--focus-color-dark);
  }
  .br-pagination.inverted .page.active, .br-pagination.dark-mode .page.active {
    --interactive-rgb: var(--active-rgb);
    background-color: var(--background-light);
    color: var(--active);
  }
  .br-pagination .input-group .input-icon {
    display: none;
  }
  */

.br-divider, hr {
  --divider-size: 1px;
  border-color: var(--color-neutral-light);
  border-style: solid;
  border-width: 0;
  border-top-width: var(--divider-size);
  display: block;
}
  

/* feedback */
.feedback {
    --proc-message-text: var(--color);
    --message-text: var(--proc-message-text);
    --proc-icon: none;
    color: var(--proc-message-text);
    font-style: normal;
    font-weight: var(--font-weight-regular);
    font-feature-settings: 'clig' off, 'liga' off;
}

.feedback:not(.is-warning):not(.warning):not([warning]) {
    --message-text: var(--proc-message-text);
}

.feedback.info,
.feedback.is-info,
.feedback[info],
.feedback.is-warning,
.feedback.warning,
.feedback[warning] {
    --message-background: var(--background);
    --feedback-background: var(--background);
    --message-color-icon: var(--color);
    --proc-message-text: var(--color);
}

.feedback.is-success,
.feedback.success,
.feedback[success] {
    --message-background: var(--background);
    --feedback-background: var(--background);
    --message-color-icon: var(--success);
    --proc-message-text: var(--color-success-dark);
    --proc-icon: '\f058';
}

.feedback.danger,
.feedback.is-danger,
.feedback[danger] {
    --message-background: var(--background);
    --feedback-background: var(--background);
    --message-color-icon: var(--danger);
    --proc-message-text: var(--color-danger-dark);
    --proc-icon: '\f057';
}

:is(.feedback.is-success,
.feedback.success,
.feedback[success],
.feedback.danger,
.feedback.is-danger,
.feedback[danger])::before {
    content: var(--proc-icon);
    font-family: 'Font Awesome 6 Free';
    font-weight: 900;
    margin-right: var(--spacing-scale-half);
}

/* tag */
.br-tag {
    --tag-size: var(--tag-small);
    --tag-small: var(--spacing-scale-2xh);
    --tag-medium: var(--spacing-scale-3xh);
    --tag-large: var(--spacing-scale-4xh);
    --proc-accent-color: var(--background-dark);
    --proc-inverted-color: var(--proc-accent-color);
    align-items: center;
    background: var(--proc-accent-color);
    border-radius: var(--surface-rounder-lg);
    color: var(--color-dark);
    display: inline-flex;
    font-size: var(--font-size-scale-down-02);
    font-weight: var(--font-weight-bold);
    justify-content: center;
    margin: 0;
    min-height: var(--tag-size);
    padding: 0 var(--spacing-scale-base);
    text-transform: uppercase;
}
/* 
.br-tag.status {
    --tag-small: var(--spacing-scale-baseh);
    --tag-medium: var(--spacing-scale-2x);
    --tag-large: var(--spacing-scale-3x);
    border: 1px solid var(--background-light);
    border-radius: 50%;
    min-width: var(--tag-size);
    padding: 0
}

.br-tag.count {
    --tag-small: var(--spacing-scale-2xh);
    --tag-medium: var(--spacing-scale-3x);
    --tag-large: var(--spacing-scale-3xh);
    border: 1px solid var(--background-light);
    border-radius: 100em;
    min-width: var(--tag-size);
    padding-left: var(--spacing-scale-base);
    padding-right: var(--spacing-scale-base)
} 

.br-tag.icon {
    --tag-small: var(--spacing-scale-3xh);
    --tag-medium: var(--spacing-scale-4x);
    --tag-large: var(--spacing-scale-5xh);
    border-radius: 50%;
    min-width: var(--tag-size);
    padding: 0
}

*/

.br-tag.icon .fa,
.br-tag.icon .fab,
.br-tag.icon .fad,
.br-tag.icon .fal,
.br-tag.icon .far,
.br-tag.icon .fas,
.br-tag.icon .svg-inline--fa {
    font-size: var(--icon-size-base);
    margin: 0 !important
}
/*
.br-tag.interaction,
.br-tag.interaction-select {
    --tag-small: var(--spacing-scale-4x);
    --tag-medium: var(--spacing-scale-5x);
    --tag-large: var(--spacing-scale-5xh);
    font-size: var(--font-size-scale-up-01)
}

.br-tag.interaction {
    background: var(--interactive)
}

.br-tag.interaction .br-button {
    --button-size: var(--spacing-scale-3xh);
    --focus: var(--focus-color-dark);
    --hover: var(--hover-dark);
    --interactive-rgb: var(--color-dark-rgb);
    background-color: var(--interactive-light);
    border-radius: 50%;
    color: var(--color-dark);
    margin-left: var(--spacing-scale-baseh);
    padding: 0;
    width: var(--button-size)
}

.br-tag.interaction:hover .br-button {
    background-image: linear-gradient(rgba(var(--interactive-rgb), var(--hover)), rgba(var(--interactive-rgb), var(--hover)))
}

.br-tag.interaction:active .br-button {
    background-image: linear-gradient(rgba(var(--interactive-rgb), var(--pressed)), rgba(var(--interactive-rgb), var(--pressed)))
}

.br-tag.interaction-select {
    background: transparent;
    padding: 0
}

.br-tag.interaction-select label {
    --interactive-rgb: var(--color-dark-rgb);
    --hover: var(--hover-dark);
    align-items: center;
    background: var(--background-dark);
    background: var(--interactive);
    border-radius: var(--surface-rounder-sm);
    color: var(--color-dark);
    cursor: pointer;
    display: inline-flex;
    font-size: var(--font-size-scale-base);
    font-size: var(--font-size-scale-up-01);
    font-weight: var(--font-weight-medium);
    justify-content: center;
    margin: 0;
    min-height: var(--tag-size);
    padding: 0 var(--spacing-scale-baseh);
    transition: padding .15s ease
}

.br-tag.interaction-select label+.br-tag.interaction-select label {
    margin-left: var(--spacing-scale-half)
}

.br-tag.interaction-select label>.fa:first-child,
.br-tag.interaction-select label>.fab:first-child,
.br-tag.interaction-select label>.fad:first-child,
.br-tag.interaction-select label>.fal:first-child,
.br-tag.interaction-select label>.far:first-child,
.br-tag.interaction-select label>.fas:first-child,
.br-tag.interaction-select label>.svg-inline--fa:first-child {
    margin-right: var(--spacing-scale-baseh)
}

.br-tag.interaction-select input {
    opacity: 0;
    position: relative;
    width: 0
}

.br-tag.interaction-select input:checked+label:after {
    border: solid var(--color-dark);
    border-width: 0 3px 3px 0;
    content: "";
    height: var(--icon-size-sm);
    transform: rotate(45deg) translate(12px, -14px);
    width: 8px
}

.br-tag.interaction-select input:focus+label {
    outline-color: var(--focus-color);
    outline-offset: var(--focus-offset);
    outline-style: var(--focus-style);
    outline-width: var(--focus-width)
}

.br-tag.interaction-select input:hover+label {
    background-image: linear-gradient(rgba(var(--interactive-rgb), var(--hover)), rgba(var(--interactive-rgb), var(--hover)))
}

.br-tag.interaction-select input:active+label {
    background-image: linear-gradient(rgba(var(--interactive-rgb), var(--pressed)), rgba(var(--interactive-rgb), var(--pressed)));
    color: var(--color-dark)
}

.br-tag.interaction-select.selected label {
    background-color: var(--selected);
    padding-right: var(--spacing-scale-4xh)
}
*/
.br-tag.disabled input {
    cursor: not-allowed
}

.br-tag+.br-tag {
    margin-left: var(--spacing-scale-half)
}

.br-tag>.fa:first-child,
.br-tag>.fab:first-child,
.br-tag>.fad:first-child,
.br-tag>.fal:first-child,
.br-tag>.far:first-child,
.br-tag>.fas:first-child,
.br-tag>.svg-inline--fa:first-child {
    margin-right: var(--spacing-scale-half);
}

.br-tag.small {
    --tag-size: var(--tag-small)
}

.br-tag.medium {
    --tag-size: var(--tag-medium)
}

.br-tag.large {
    --tag-size: var(--tag-large)
}

.br-tag.is-primary,
.br-tag.primary,
.br-tag[primary] {
    --proc-accent-color: var(--color-primary-interactive-area);
}

/* necessário background-color pra sobrescrever a regra original */
.br-tag.highlight,
.br-tag.is-highlight,
.br-tag[highlight] {
    background-color: var(--proc-accent-color);
    --proc-accent-color: var(--color-highlight-focus);
}

.br-tag.is-success,
.br-tag.success,
.br-tag[success] {
    --proc-accent-color: var(--success);
}

.br-tag.danger,
.br-tag.is-danger,
.br-tag[danger] {
    --proc-accent-color: var(--danger);
}

.br-tag.is-warning,
.br-tag.warning,
.br-tag[warning] {
    --proc-accent-color: var(--warning);
    --proc-inverted-color: var(--color-warning-dark);
    color: var(--color-light);
}

.br-tag.info,
.br-tag.is-info,
.br-tag[info] {
    --proc-accent-color: var(--info-base);
}

.br-tag.light,
.br-tag.is-light,
.br-tag[light] {
    background-color: var(--color-neutral-background);
    color: var(--proc-inverted-color);
}

/* select */
.br-select .br-radio input {
    opacity: 0;
    position: absolute;
}

/* tipografia */
h1, h2, h3, h4, h5, h6, 
.h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: var(--font-family-titles);
    margin-bottom: var(--font-size-scale-base);
}

.h1, h1 {
    font-size: var(--font-size-scale-up-05);
    color: var(--color-primary-surfaces);
    font-weight: var(--font-weight-semi-bold);
}

.h2, h2 {
    font-size: var(--font-size-scale-up-03);
    color: var(--color-primary-headings);
    font-weight: var(--font-weight-semi-bold);
}

.h3, h3 {
    font-size: var(--font-size-scale-up-02);
    color: var(--color-primary-headings);
    font-weight: var(--font-weight-bold);
}

.h4, h4 {
    font-size: var(--font-size-scale-up-01);
    color: var(--color-primary-headings);
    font-weight: var(--font-weight-bold);
}

.h5, h5 {
    font-size: var(--font-size-scale-base);
    color: var(--color-primary-headings);
    font-weight: var(--font-weight-extra-bold);
    text-transform: uppercase;
}

.h6, h6 {
    font-size: var(--font-size-scale-down-02);
    color: var(--color-primary-headings);
    font-weight: var(--font-weight-extra-bold);
    text-transform: uppercase;
}

p {
    font-size: var(--font-size-scale-base);
}

/* tabs */
.tab-nav {
  --tab-padding: var(--font-size-scale-up-01);
  --tab-size: var(--tab-medium);
//  --tab-large: var(--spacing-scale-3x);
  --tab-medium: var(--font-size-scale-base);
 // --tab-small: var(--spacing-scale-base);
  --grid-breakpoint-sm: 576px; //passar pra folha de tokens
  --active: var(--color-primary-interactive-area);
}
  
.tab-nav {
  overflow-x: auto;
  width: 100%;
  
  @media (min-width: var(--grid-breakpoint-sm)) {
    display: flex;
    overflow-x: auto;
    width: 100%;
  }
  ul {
    border-bottom: 1px solid var(--color-neutral-light);
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0;
    margin-top: 0;
    padding: 0;
    width: 100%;
  }
}

.tab-item {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  white-space: nowrap;

  button,
  a {
    //--focus-offset: calc(var(--spacing-scale-half) * -1);
    //@include focus;
    //@include hover("color");
    background-color: transparent;
    border: 0;
    border-bottom: 2px solid transparent;
    color: var(--color-primary-headings);
    display: inline-flex;
    font-weight: var(--font-weight-semi-bold);
    line-height: 1;
    padding: var(--tab-size) var(--tab-padding);
    white-space: nowrap;
    //height: 3.5rem;
    justify-content: center;
    align-items: flex-end;
    gap: var(--spacing-scale-half);
    &:hover {
      color: var(--active);
    }
    &:not(:disabled):not(:disabled):focus-visible {
      outline: var(--color-highlight-focus) solid 4px;
    }
  }
/*
  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
  */

  &.is-active,
  &.active {
    button {
      border-bottom-color: var(--active);
      color: var(--active);
      &:hover {
        color: var(--color-primary-headings);
      }
    }
  }
}