/* Tokens DS Procempa - versão obtida em 04/10/2023 de https://git.procempa.com.br/design/design-system */

:root {
    --blue-90: #07193A;
    --blue-80: #0C2B64;
    --blue-70: #1A4CA8;
    --blue-60: #2E65CC;
    --blue-50: #457EE7;
    --blue-40: #6495ed;
    --blue-30: #91b3f2;
    --blue-20: #abc5f5;
    --blue-15: #bfd3f8;
    --blue-10: #d4e1fa;
    --blue-5: #eff4fd;
    
    /*
    --blue-cool-90: #0f191c;
    --blue-cool-80: #14333d;
    --blue-cool-70: #224a58;
    --blue-cool-60: #2e6276;
    --blue-cool-50: #3a7d95;
    --blue-cool-40: #6499af;
    --blue-cool-30: #82b4c9;
    --blue-cool-20: #adcfdc;
    --blue-cool-10: #dae9ee;
    --blue-cool-5: #e7f2f5;
    --blue-warm-90: #13171f;
    --blue-warm-80: #252f3e;
    --blue-warm-70: #2f4668;
    --blue-warm-60: #345d96;
    --blue-warm-50: #4a77b4;
    --blue-warm-40: #7292c7;
    --blue-warm-30: #98afd2;
    --blue-warm-20: #c5d4eb;
    --blue-warm-10: #e1e7f1;
    --blue-warm-5: #ecf1f7;
    --blue-vivid-80: #112f4e;
    --blue-vivid-70: #0b4778;
    --blue-vivid-60: #005ea2;
    --blue-vivid-50: #0076d6;
    --blue-vivid-40: #2491ff;
    --blue-vivid-30: #58b4ff;
    --blue-vivid-20: #a1d3ff;
    --blue-vivid-10: #cfe8ff;
    --blue-vivid-5: #e8f5ff;
    --blue-cool-vivid-80: #002d3f;
    --blue-cool-vivid-70: #074b69;
    --blue-cool-vivid-60: #07648d;
    --blue-cool-vivid-50: #0d7ea2;
    --blue-cool-vivid-40: #28a0cb;
    --blue-cool-vivid-30: #59b9de;
    --blue-cool-vivid-20: #97d4ea;
    --blue-cool-vivid-10: #c3ebfa;
    --blue-cool-vivid-5: #e1f3f8;
    --blue-warm-vivid-90: #071d41;
    --blue-warm-vivid-80: #0c326f;
    --blue-warm-vivid-70: #1351b4;
    --blue-warm-vivid-60: #155bcb;
    --blue-warm-vivid-50: #2670e8;
    --blue-warm-vivid-40: #5992ed;
    --blue-warm-vivid-30: #81aefc;
    --blue-warm-vivid-20: #adcdff;
    --blue-warm-vivid-10: #d4e5ff;
    --blue-warm-vivid-5: #edf5ff;
    */
    --blue-90-rgb: 7, 25, 58;
    --blue-80-rgb: 12, 43, 100;
    --blue-70-rgb: 26, 76, 168;
    --blue-60-rgb: 46, 101, 204;
    --blue-50-rgb: 69, 126, 231;
    --blue-40-rgb: 100, 149, 237;
    --blue-30-rgb: 145, 179, 242;
    --blue-20-rgb: 171, 197, 245;
    --blue-15-rgb: 191, 211, 248;
    --blue-10-rgb: 212, 225, 250;
    --blue-5-rgb: 239, 244, 253;
    /*
    --blue-cool-90-rgb: 15,25,28;
    --blue-cool-80-rgb: 20,51,61;
    --blue-cool-70-rgb: 34,74,88;
    --blue-cool-60-rgb: 46,98,118;
    --blue-cool-50-rgb: 58,125,149;
    --blue-cool-40-rgb: 100,153,175;
    --blue-cool-30-rgb: 130,180,201;
    --blue-cool-20-rgb: 173,207,220;
    --blue-cool-10-rgb: 218,233,238;
    --blue-cool-5-rgb: 231,242,245;
    --blue-warm-90-rgb: 19,23,31;
    --blue-warm-80-rgb: 37,47,62;
    --blue-warm-70-rgb: 47,70,104;
    --blue-warm-60-rgb: 52,93,150;
    --blue-warm-50-rgb: 74,119,180;
    --blue-warm-40-rgb: 114,146,199;
    --blue-warm-30-rgb: 152,175,210;
    --blue-warm-20-rgb: 197,212,235;
    --blue-warm-10-rgb: 225,231,241;
    --blue-warm-5-rgb: 236,241,247;
    --blue-vivid-80-rgb: 17,47,78;
    --blue-vivid-70-rgb: 11,71,120;
    --blue-vivid-60-rgb: 0,94,162;
    --blue-vivid-50-rgb: 0,118,214;
    --blue-vivid-40-rgb: 36,145,255;
    --blue-vivid-30-rgb: 88,180,255;
    --blue-vivid-20-rgb: 161,211,255;
    --blue-vivid-10-rgb: 207,232,255;
    --blue-vivid-5-rgb: 232,245,255;
    --blue-cool-vivid-80-rgb: 0,45,63;
    --blue-cool-vivid-70-rgb: 7,75,105;
    --blue-cool-vivid-60-rgb: 7,100,141;
    --blue-cool-vivid-50-rgb: 13,126,162;
    --blue-cool-vivid-40-rgb: 40,160,203;
    --blue-cool-vivid-30-rgb: 89,185,222;
    --blue-cool-vivid-20-rgb: 151,212,234;
    --blue-cool-vivid-10-rgb: 195,235,250;
    --blue-cool-vivid-5-rgb: 225,243,248;
    --blue-warm-vivid-90-rgb: 7,29,65;
    --blue-warm-vivid-80-rgb: 12,50,111;
    --blue-warm-vivid-70-rgb: 19,81,180;
    --blue-warm-vivid-60-rgb: 21,91,203;
    --blue-warm-vivid-50-rgb: 38,112,232;
    --blue-warm-vivid-40-rgb: 89,146,237;
    --blue-warm-vivid-30-rgb: 129,174,252;
    --blue-warm-vivid-20-rgb: 173,205,255;
    --blue-warm-vivid-10-rgb: 212,229,255;
    --blue-warm-vivid-5-rgb: 237,245,255;
    */
    --cyan-90: #081e1d;
    --cyan-80: #0e3735;
    --cyan-70: #0a5451;
    --cyan-60: #0f6d69;
    --cyan-50: #148380;
    --cyan-40: #2aa5a1;
    --cyan-30: #2aa5a1;
    --cyan-20: #48d1cc;
    --cyan-15: #74dcd8;
    --cyan-10: #b2ece9;
    --cyan-5: #e2f8f7;
  
    --cyan-90-rgb: 8, 30, 29;
    --cyan-80-rgb: 14, 55, 53;
    --cyan-70-rgb: 10, 84, 81;
    --cyan-60-rgb: 15, 109, 105;
    --cyan-50-rgb: 20, 131, 128;
    --cyan-40-rgb: 42, 165, 161;
    --cyan-30-rgb: 42, 165, 161;
    --cyan-20-rgb: 72, 209, 204;
    --cyan-15-rgb: 116, 220, 216;
    --cyan-10-rgb: 178, 236, 233;
    --cyan-5-rgb: 226, 248, 247;
    /*
    --cyan-vivid-80: #093b44;
    --cyan-vivid-70: #0e4f5c;
    --cyan-vivid-60: #00687d;
    --cyan-vivid-50: #0081a1;
    --cyan-vivid-40: #009ec1;
    --cyan-vivid-30: #00bde3;
    --cyan-vivid-20: #52daf2;
    --cyan-vivid-10: #a8f2ff;
    --cyan-vivid-5: #e5faff;
    --gold-90: #191714;
    --gold-80: #322d26;
    --gold-70: #4d4438;
    --gold-60: #6b5947;
    --gold-50: #8e704f;
    --gold-40: #ad8b65;
    --gold-30: #c7a97b;
    --gold-20: #dec69a;
    --gold-10: #f1e5cd;
    --gold-5: #f5f0e6;
    --gold-vivid-80: #3b2b15;
    --gold-vivid-70: #5c410a;
    --gold-vivid-60: #7a591a;
    --gold-vivid-50: #936f38;
    --gold-vivid-40: #c2850c;
    --gold-vivid-30: #e5a000;
    --gold-vivid-20: #ffbe2e;
    --gold-vivid-10: #ffe396;
    --gold-vivid-5: #fef0c8;
    --gold-90-rgb: 25,23,20;
    --gold-80-rgb: 50,45,38;
    --gold-70-rgb: 77,68,56;
    --gold-60-rgb: 107,89,71;
    --gold-50-rgb: 142,112,79;
    --gold-40-rgb: 173,139,101;
    --gold-30-rgb: 199,169,123;
    --gold-20-rgb: 222,198,154;
    --gold-10-rgb: 241,229,205;
    --gold-5-rgb: 245,240,230;
    --gold-vivid-80-rgb: 59,43,21;
    --gold-vivid-70-rgb: 92,65,10;
    --gold-vivid-60-rgb: 122,89,26;
    --gold-vivid-50-rgb: 147,111,56;
    --gold-vivid-40-rgb: 194,133,12;
    --gold-vivid-30-rgb: 229,160,0;
    --gold-vivid-20-rgb: 255,190,46;
    --gold-vivid-10-rgb: 255,227,150;
    --gold-vivid-5-rgb: 254,240,200;
    */

    --gray-90: #091e42;
    --gray-80: #172b4d;
    --gray-70: #2c3e5d;
    --gray-60: #44546f;
    --gray-50: #626f86;
    --gray-40: #8590a2;
    --gray-30: #9da5b3;
    --gray-20: #b3b9c4;
    --gray-15: #dcdee4;
    --gray-10: #e6e7eb;
    --gray-5: #f1f2f4;

    /*
    --gray-4: #f3f3f3;
    --gray-3: #f6f6f6;
    --gray-2: #f8f8f8;
    --gray-1: #fcfcfc;
    --gray-cool-90: #1c1d1f;
    --gray-cool-80: #2d2e2f;
    --gray-cool-70: #3d4551;
    --gray-cool-60: #565c65;
    --gray-cool-50: #71767a;
    --gray-cool-40: #8d9297;
    --gray-cool-30: #a9aeb1;
    --gray-cool-20: #c6cace;
    --gray-cool-10: #dfe1e2;
    --gray-cool-5: #edeff0;
    --gray-cool-4: #f1f3f6;
    --gray-cool-3: #f5f6f7;
    --gray-cool-2: #f7f9fa;
    --gray-cool-1: #fbfcfd;
    --gray-warm-90: #171716;
    --gray-warm-80: #2e2e2a;
    --gray-warm-70: #454540;
    --gray-warm-60: #5d5d52;
    --gray-warm-50: #76766a;
    --gray-warm-40: #929285;
    --gray-warm-30: #afaea2;
    --gray-warm-20: #cac9c0;
    --gray-warm-10: #e6e6e2;
    --gray-warm-5: #f0f0ec;
    --gray-warm-4: #f5f5f0;
    --gray-warm-3: #f6f6f2;
    --gray-warm-2: #f9f9f7;
    --gray-warm-1: #fcfcfb;
    */

    --gray-90-rgb: 9, 30, 66;
    --gray-80-rgb: 23, 43, 77;
    --gray-70-rgb: 44, 62, 93;
    --gray-60-rgb: 68, 84, 111;
    --gray-50-rgb: 98, 111, 134;
    --gray-40-rgb: 133, 144, 162;
    --gray-30-rgb: 157, 165, 179;
    --gray-20-rgb: 179, 185, 196;
    --gray-15-rgb: 220, 222, 228;
    --gray-10-rgb: 230, 231, 235;
    --gray-5-rgb: 241, 242, 244;

/*    
    --gray-4-rgb: 243,243,243;
    --gray-3-rgb: 246,246,246;
    --gray-2-rgb: 248,248,248;
    --gray-1-rgb: 252,252,252;
    --gray-cool-90-rgb: 28,29,31;
    --gray-cool-80-rgb: 45,46,47;
    --gray-cool-70-rgb: 61,69,81;
    --gray-cool-60-rgb: 86,92,101;
    --gray-cool-50-rgb: 113,118,122;
    --gray-cool-40-rgb: 141,146,151;
    --gray-cool-30-rgb: 169,174,177;
    --gray-cool-20-rgb: 198,202,206;
    --gray-cool-10-rgb: 223,225,226;
    --gray-cool-5-rgb: 237,239,240;
    --gray-cool-4-rgb: 241,243,246;
    --gray-cool-3-rgb: 245,246,247;
    --gray-cool-2-rgb: 247,249,250;
    --gray-cool-1-rgb: 251,252,253;
    --gray-warm-90-rgb: 23,23,22;
    --gray-warm-80-rgb: 46,46,42;
    --gray-warm-70-rgb: 69,69,64;
    --gray-warm-60-rgb: 93,93,82;
    --gray-warm-50-rgb: 118,118,106;
    --gray-warm-40-rgb: 146,146,133;
    --gray-warm-30-rgb: 175,174,162;
    --gray-warm-20-rgb: 202,201,192;
    --gray-warm-10-rgb: 230,230,226;
    --gray-warm-5-rgb: 240,240,236;
    --gray-warm-4-rgb: 245,245,240;
    --gray-warm-3-rgb: 246,246,242;
    --gray-warm-2-rgb: 249,249,247;
    --gray-warm-1-rgb: 252,252,251;
    */

    --green-90: #111d11;
    --green-80: #203620;
    --green-70: #315331;
    --green-60: #406b40;
    --green-50: #4d824d;
    --green-40: #64a364;
    --green-30: #8ebc8e;
    --green-20: #9ec69e;
    --green-15: #b5d3b5;
    --green-10: #d4e5d4;
    --green-5: #eff5ef;

    /*
    --green-cool-90: #1a1f1a;
    --green-cool-80: #28312a;
    --green-cool-70: #37493b;
    --green-cool-60: #446443;
    --green-cool-50: #4d8055;
    --green-cool-40: #5e9f69;
    --green-cool-30: #86b98e;
    --green-cool-20: #b4d0b9;
    --green-cool-10: #dbebde;
    --green-cool-5: #ecf3ec;
    --green-warm-90: #171712;
    --green-warm-80: #2d2f21;
    --green-warm-70: #45472f;
    --green-warm-60: #5a5f38;
    --green-warm-50: #6f7a41;
    --green-warm-40: #8a984b;
    --green-warm-30: #a6b557;
    --green-warm-20: #cbd17a;
    --green-warm-10: #e7eab7;
    --green-warm-5: #f1f4d7;
    --green-vivid-80: #243413;
    --green-vivid-70: #2f4a0b;
    --green-vivid-60: #466c04;
    --green-vivid-50: #538200;
    --green-vivid-40: #719f2a;
    --green-vivid-30: #7fb135;
    --green-vivid-20: #98d035;
    --green-vivid-10: #c5ee93;
    --green-vivid-5: #ddf9c7;
    --green-cool-vivid-80: #19311e;
    --green-cool-vivid-70: #154c21;
    --green-cool-vivid-60: #216e1f;
    --green-cool-vivid-50: #168821;
    --green-cool-vivid-40: #00a91c;
    --green-cool-vivid-30: #21c834;
    --green-cool-vivid-20: #70e17b;
    --green-cool-vivid-10: #b7f5bd;
    --green-cool-vivid-5: #e3f5e1;
    --green-warm-vivid-80: #38380b;
    --green-warm-vivid-70: #4b4e10;
    --green-warm-vivid-60: #5a6613;
    --green-warm-vivid-50: #6a7d00;
    --green-warm-vivid-40: #7e9c1d;
    --green-warm-vivid-30: #a3b72c;
    --green-warm-vivid-20: #c5d30a;
    --green-warm-vivid-10: #e7f434;
    --green-warm-vivid-5: #f5fbc1;
    */
    --green-90-rgb: 17, 29, 17;
    --green-80-rgb: 32, 54, 32;
    --green-70-rgb: 49, 83, 49;
    --green-60-rgb: 64, 107, 64;
    --green-50-rgb: 77, 130, 77;
    --green-40-rgb: 100, 163, 100;
    --green-30-rgb: 142, 188, 142;
    --green-20-rgb: 158, 198, 158;
    --green-15-rgb: 181, 211, 181;
    --green-10-rgb: 212, 229, 212;
    --green-5-rgb: 239, 245, 239;
    /*
    --green-cool-90-rgb: 26,31,26;
    --green-cool-80-rgb: 40,49,42;
    --green-cool-70-rgb: 55,73,59;
    --green-cool-60-rgb: 68,100,67;
    --green-cool-50-rgb: 77,128,85;
    --green-cool-40-rgb: 94,159,105;
    --green-cool-30-rgb: 134,185,142;
    --green-cool-20-rgb: 180,208,185;
    --green-cool-10-rgb: 219,235,222;
    --green-cool-5-rgb: 236,243,236;
    --green-warm-90-rgb: 23,23,18;
    --green-warm-80-rgb: 45,47,33;
    --green-warm-70-rgb: 69,71,47;
    --green-warm-60-rgb: 90,95,56;
    --green-warm-50-rgb: 111,122,65;
    --green-warm-40-rgb: 138,152,75;
    --green-warm-30-rgb: 166,181,87;
    --green-warm-20-rgb: 203,209,122;
    --green-warm-10-rgb: 231,234,183;
    --green-warm-5-rgb: 241,244,215;
    --green-vivid-80-rgb: 36,52,19;
    --green-vivid-70-rgb: 47,74,11;
    --green-vivid-60-rgb: 70,108,4;
    --green-vivid-50-rgb: 83,130,0;
    --green-vivid-40-rgb: 113,159,42;
    --green-vivid-30-rgb: 127,177,53;
    --green-vivid-20-rgb: 152,208,53;
    --green-vivid-10-rgb: 197,238,147;
    --green-vivid-5-rgb: 221,249,199;
    --green-cool-vivid-80-rgb: 25,49,30;
    --green-cool-vivid-70-rgb: 21,76,33;
    --green-cool-vivid-60-rgb: 33,110,31;
    --green-cool-vivid-50-rgb: 22,136,33;
    --green-cool-vivid-40-rgb: 0,169,28;
    --green-cool-vivid-30-rgb: 33,200,52;
    --green-cool-vivid-20-rgb: 112,225,123;
    --green-cool-vivid-10-rgb: 183,245,189;
    --green-cool-vivid-5-rgb: 227,245,225;
    --green-warm-vivid-80-rgb: 56,56,11;
    --green-warm-vivid-70-rgb: 75,78,16;
    --green-warm-vivid-60-rgb: 90,102,19;
    --green-warm-vivid-50-rgb: 106,125,0;
    --green-warm-vivid-40-rgb: 126,156,29;
    --green-warm-vivid-30-rgb: 163,183,44;
    --green-warm-vivid-20-rgb: 197,211,10;
    --green-warm-vivid-10-rgb: 231,244,52;
    --green-warm-vivid-5-rgb: 245,251,193;
    --indigo-90: #16171f;
    --indigo-80: #2b2c40;
    --indigo-70: #3d4076;
    --indigo-60: #4d52af;
    --indigo-50: #676cc8;
    --indigo-40: #8889db;
    --indigo-30: #a5a8eb;
    --indigo-20: #c5c5f3;
    --indigo-10: #e5e4fa;
    --indigo-5: #efeff8;
    --indigo-cool-90: #151622;
    --indigo-cool-80: #292d42;
    --indigo-cool-70: #374274;
    --indigo-cool-60: #3f57a6;
    --indigo-cool-50: #496fd8;
    --indigo-cool-40: #6b8ee8;
    --indigo-cool-30: #96abee;
    --indigo-cool-20: #bbc8f5;
    --indigo-cool-10: #e1e6f9;
    --indigo-cool-5: #eef0f9;
    --indigo-warm-90: #18161d;
    --indigo-warm-80: #2e2c40;
    --indigo-warm-70: #453c7b;
    --indigo-warm-60: #5e519e;
    --indigo-warm-50: #7665d1;
    --indigo-warm-40: #9287d8;
    --indigo-warm-30: #afa5e8;
    --indigo-warm-20: #cbc4f2;
    --indigo-warm-10: #e7e3fa;
    --indigo-warm-5: #f1eff7;
    --indigo-vivid-80: #212463;
    --indigo-vivid-70: #3333a3;
    --indigo-vivid-60: #4a50c4;
    --indigo-vivid-50: #656bd7;
    --indigo-vivid-40: #8289ff;
    --indigo-vivid-30: #a3a7fa;
    --indigo-vivid-20: #ccceff;
    --indigo-vivid-10: #e0e0ff;
    --indigo-vivid-5: #f0f0ff;
    --indigo-cool-vivid-80: #1b2b85;
    --indigo-cool-vivid-70: #222fbf;
    --indigo-cool-vivid-60: #3e4ded;
    --indigo-cool-vivid-50: #4866ff;
    --indigo-cool-vivid-40: #628ef4;
    --indigo-cool-vivid-30: #94adff;
    --indigo-cool-vivid-20: #b8c8ff;
    --indigo-cool-vivid-10: #dee5ff;
    --indigo-cool-vivid-5: #edf0ff;
    --indigo-warm-vivid-80: #261f5b;
    --indigo-warm-vivid-70: #3d2c9d;
    --indigo-warm-vivid-60: #5942d2;
    --indigo-warm-vivid-50: #745fe9;
    --indigo-warm-vivid-40: #967efb;
    --indigo-warm-vivid-30: #b69fff;
    --indigo-warm-vivid-20: #cfc4fd;
    --indigo-warm-vivid-10: #e4deff;
    --indigo-warm-vivid-5: #f5f2ff;
    --indigo-90-rgb: 22,23,31;
    --indigo-80-rgb: 43,44,64;
    --indigo-70-rgb: 61,64,118;
    --indigo-60-rgb: 77,82,175;
    --indigo-50-rgb: 103,108,200;
    --indigo-40-rgb: 136,137,219;
    --indigo-30-rgb: 165,168,235;
    --indigo-20-rgb: 197,197,243;
    --indigo-10-rgb: 229,228,250;
    --indigo-5-rgb: 239,239,248;
    --indigo-cool-90-rgb: 21,22,34;
    --indigo-cool-80-rgb: 41,45,66;
    --indigo-cool-70-rgb: 55,66,116;
    --indigo-cool-60-rgb: 63,87,166;
    --indigo-cool-50-rgb: 73,111,216;
    --indigo-cool-40-rgb: 107,142,232;
    --indigo-cool-30-rgb: 150,171,238;
    --indigo-cool-20-rgb: 187,200,245;
    --indigo-cool-10-rgb: 225,230,249;
    --indigo-cool-5-rgb: 238,240,249;
    --indigo-warm-90-rgb: 24,22,29;
    --indigo-warm-80-rgb: 46,44,64;
    --indigo-warm-70-rgb: 69,60,123;
    --indigo-warm-60-rgb: 94,81,158;
    --indigo-warm-50-rgb: 118,101,209;
    --indigo-warm-40-rgb: 146,135,216;
    --indigo-warm-30-rgb: 175,165,232;
    --indigo-warm-20-rgb: 203,196,242;
    --indigo-warm-10-rgb: 231,227,250;
    --indigo-warm-5-rgb: 241,239,247;
    --indigo-vivid-80-rgb: 33,36,99;
    --indigo-vivid-70-rgb: 51,51,163;
    --indigo-vivid-60-rgb: 74,80,196;
    --indigo-vivid-50-rgb: 101,107,215;
    --indigo-vivid-40-rgb: 130,137,255;
    --indigo-vivid-30-rgb: 163,167,250;
    --indigo-vivid-20-rgb: 204,206,255;
    --indigo-vivid-10-rgb: 224,224,255;
    --indigo-vivid-5-rgb: 240,240,255;
    --indigo-cool-vivid-80-rgb: 27,43,133;
    --indigo-cool-vivid-70-rgb: 34,47,191;
    --indigo-cool-vivid-60-rgb: 62,77,237;
    --indigo-cool-vivid-50-rgb: 72,102,255;
    --indigo-cool-vivid-40-rgb: 98,142,244;
    --indigo-cool-vivid-30-rgb: 148,173,255;
    --indigo-cool-vivid-20-rgb: 184,200,255;
    --indigo-cool-vivid-10-rgb: 222,229,255;
    --indigo-cool-vivid-5-rgb: 237,240,255;
    --indigo-warm-vivid-80-rgb: 38,31,91;
    --indigo-warm-vivid-70-rgb: 61,44,157;
    --indigo-warm-vivid-60-rgb: 89,66,210;
    --indigo-warm-vivid-50-rgb: 116,95,233;
    --indigo-warm-vivid-40-rgb: 150,126,251;
    --indigo-warm-vivid-30-rgb: 182,159,255;
    --indigo-warm-vivid-20-rgb: 207,196,253;
    --indigo-warm-vivid-10-rgb: 228,222,255;
    --indigo-warm-vivid-5-rgb: 245,242,255;
*/
    --magenta-90: #320d19;
    --magenta-80: #5b172d;
    --magenta-70: #8a2345;
    --magenta-60: #b22d58;
    --magenta-50: #cf3f6f;
    --magenta-40: #db7093;
    --magenta-30: #e493ad;
    --magenta-20: #eaaabf;
    --magenta-15: #efbfcf;
    --magenta-10: #f6dae3;
    --magenta-5: #fbf1f4;
    /*
    --magenta-vivid-80: #4f172e;
    --magenta-vivid-70: #731f44;
    --magenta-vivid-60: #ab2165;
    --magenta-vivid-50: #d72d79;
    --magenta-vivid-40: #fd4496;
    --magenta-vivid-30: #ff87b2;
    --magenta-vivid-20: #ffb4cf;
    --magenta-vivid-10: #ffddea;
    --magenta-vivid-5: #fff2f5;
    */
    --magenta-90-rgb: 50, 13, 25;
    --magenta-80-rgb: 91, 23, 45;
    --magenta-70-rgb: 138, 35, 69;
    --magenta-60-rgb: 178, 45, 88;
    --magenta-50-rgb: 207, 63, 111;
    --magenta-40-rgb: 219, 112, 147;
    --magenta-30-rgb: 228, 147, 173;
    --magenta-20-rgb: 234, 170, 191;
    --magenta-15-rgb: 239, 191, 207;
    --magenta-10-rgb: 246, 218, 227;
    --magenta-5-rgb: 251, 241, 244;
    /*
    --magenta-vivid-80-rgb: 79,23,46;
    --magenta-vivid-70-rgb: 115,31,68;
    --magenta-vivid-60-rgb: 171,33,101;
    --magenta-vivid-50-rgb: 215,45,121;
    --magenta-vivid-40-rgb: 253,68,150;
    --magenta-vivid-30-rgb: 255,135,178;
    --magenta-vivid-20-rgb: 255,180,207;
    --magenta-vivid-10-rgb: 255,221,234;
    --magenta-vivid-5-rgb: 255,242,245;
    --mint-90: #0d1a12;
    --mint-80: #193324;
    --mint-70: #204e34;
    --mint-60: #286846;
    --mint-50: #2e8367;
    --mint-40: #34a37e;
    --mint-30: #5abf95;
    --mint-20: #92d9bb;
    --mint-10: #c7efe2;
    --mint-5: #dbf6ed;
    --mint-cool-90: #111818;
    --mint-cool-80: #203131;
    --mint-cool-70: #2a4b45;
    --mint-cool-60: #376462;
    --mint-cool-50: #40807e;
    --mint-cool-40: #4f9e99;
    --mint-cool-30: #6fbab3;
    --mint-cool-20: #9bd4cf;
    --mint-cool-10: #c4eeeb;
    --mint-cool-5: #e0f7f6;
    --mint-vivid-80: #0d351e;
    --mint-vivid-70: #0c4e29;
    --mint-vivid-60: #146947;
    --mint-vivid-50: #008659;
    --mint-vivid-40: #00a871;
    --mint-vivid-30: #04c585;
    --mint-vivid-20: #0ceda6;
    --mint-vivid-10: #83fcd4;
    --mint-vivid-5: #c9fbeb;
    --mint-cool-vivid-80: #123131;
    --mint-cool-vivid-70: #0b4b3f;
    --mint-cool-vivid-60: #0f6460;
    --mint-cool-vivid-50: #008480;
    --mint-cool-vivid-40: #36a191;
    --mint-cool-vivid-30: #1dc2ae;
    --mint-cool-vivid-20: #40e0d0;
    --mint-cool-vivid-10: #7efbe1;
    --mint-cool-vivid-5: #d5fbf3;
    --mint-90-rgb: 13,26,18;
    --mint-80-rgb: 25,51,36;
    --mint-70-rgb: 32,78,52;
    --mint-60-rgb: 40,104,70;
    --mint-50-rgb: 46,131,103;
    --mint-40-rgb: 52,163,126;
    --mint-30-rgb: 90,191,149;
    --mint-20-rgb: 146,217,187;
    --mint-10-rgb: 199,239,226;
    --mint-5-rgb: 219,246,237;
    --mint-cool-90-rgb: 17,24,24;
    --mint-cool-80-rgb: 32,49,49;
    --mint-cool-70-rgb: 42,75,69;
    --mint-cool-60-rgb: 55,100,98;
    --mint-cool-50-rgb: 64,128,126;
    --mint-cool-40-rgb: 79,158,153;
    --mint-cool-30-rgb: 111,186,179;
    --mint-cool-20-rgb: 155,212,207;
    --mint-cool-10-rgb: 196,238,235;
    --mint-cool-5-rgb: 224,247,246;
    --mint-vivid-80-rgb: 13,53,30;
    --mint-vivid-70-rgb: 12,78,41;
    --mint-vivid-60-rgb: 20,105,71;
    --mint-vivid-50-rgb: 0,134,89;
    --mint-vivid-40-rgb: 0,168,113;
    --mint-vivid-30-rgb: 4,197,133;
    --mint-vivid-20-rgb: 12,237,166;
    --mint-vivid-10-rgb: 131,252,212;
    --mint-vivid-5-rgb: 201,251,235;
    --mint-cool-vivid-80-rgb: 18,49,49;
    --mint-cool-vivid-70-rgb: 11,75,63;
    --mint-cool-vivid-60-rgb: 15,100,96;
    --mint-cool-vivid-50-rgb: 0,132,128;
    --mint-cool-vivid-40-rgb: 54,161,145;
    --mint-cool-vivid-30-rgb: 29,194,174;
    --mint-cool-vivid-20-rgb: 64,224,208;
    --mint-cool-vivid-10-rgb: 126,251,225;
    --mint-cool-vivid-5-rgb: 213,251,243;
    */
    --orange-90: #2f1107;
    --orange-80: #56200c;
    --orange-70: #8b2812;
    --orange-60: #b1360d;
    --orange-50: #ce4920;
    --orange-40: #f06a40;
    --orange-30: #ff7f50;
    --orange-20: #ffa685;
    --orange-15: #ffbca3;
    --orange-10: #ffd9cc;
    --orange-5: #ffefea;

/*
    --orange-warm-90: #1c1615;
    --orange-warm-80: #3d2925;
    --orange-warm-70: #633a32;
    --orange-warm-60: #914734;
    --orange-warm-50: #bd5727;
    --orange-warm-40: #e17141;
    --orange-warm-30: #f3966d;
    --orange-warm-20: #f7bca2;
    --orange-warm-10: #fbe0d0;
    --orange-warm-5: #faeee5;
    --orange-vivid-80: #352313;
    --orange-vivid-70: #5f3617;
    --orange-vivid-60: #8c471c;
    --orange-vivid-50: #c05600;
    --orange-vivid-40: #e66f0e;
    --orange-vivid-30: #ff8c00;
    --orange-vivid-20: #ffbc78;
    --orange-vivid-10: #fce2c5;
    --orange-vivid-5: #fdf5e6;
    --orange-warm-vivid-80: #3d231d;
    --orange-warm-vivid-70: #782312;
    --orange-warm-vivid-60: #a72f10;
    --orange-warm-vivid-50: #cf4900;
    --orange-warm-vivid-40: #ff580a;
    --orange-warm-vivid-30: #fc906d;
    --orange-warm-vivid-20: #fbbaa7;
    --orange-warm-vivid-10: #ffe2d1;
    --orange-warm-vivid-5: #fff3ea;
    */
    --orange-90-rgb: 47, 17, 7;
    --orange-80-rgb: 86, 32, 12;
    --orange-70-rgb: 139, 40, 18;
    --orange-60-rgb: 177, 54, 13;
    --orange-50-rgb: 206, 73, 32;
    --orange-40-rgb: 240, 106, 64;
    --orange-30-rgb: 255, 127, 80;
    --orange-20-rgb: 255, 166, 133;
    --orange-15-rgb: 255, 188, 163;
    --orange-10-rgb: 255, 217, 204;
    --orange-5-rgb: 255, 239, 234;
    /*
    --orange-warm-90-rgb: 28,22,21;
    --orange-warm-80-rgb: 61,41,37;
    --orange-warm-70-rgb: 99,58,50;
    --orange-warm-60-rgb: 145,71,52;
    --orange-warm-50-rgb: 189,87,39;
    --orange-warm-40-rgb: 225,113,65;
    --orange-warm-30-rgb: 243,150,109;
    --orange-warm-20-rgb: 247,188,162;
    --orange-warm-10-rgb: 251,224,208;
    --orange-warm-5-rgb: 250,238,229;
    --orange-vivid-80-rgb: 53,35,19;
    --orange-vivid-70-rgb: 95,54,23;
    --orange-vivid-60-rgb: 140,71,28;
    --orange-vivid-50-rgb: 192,86,0;
    --orange-vivid-40-rgb: 230,111,14;
    --orange-vivid-30-rgb: 255,140,0;
    --orange-vivid-20-rgb: 255,188,120;
    --orange-vivid-10-rgb: 252,226,197;
    --orange-vivid-5-rgb: 253,245,230;
    --orange-warm-vivid-80-rgb: 61,35,29;
    --orange-warm-vivid-70-rgb: 120,35,18;
    --orange-warm-vivid-60-rgb: 167,47,16;
    --orange-warm-vivid-50-rgb: 207,73,0;
    --orange-warm-vivid-40-rgb: 255,88,10;
    --orange-warm-vivid-30-rgb: 252,144,109;
    --orange-warm-vivid-20-rgb: 251,186,167;
    --orange-warm-vivid-10-rgb: 255,226,209;
    --orange-warm-vivid-5-rgb: 255,243,234;
    */
    --pure-100: #000;
    --pure-0: #fff;
    --pure-100-rgb: 0,0,0;
    --pure-0-rgb: 255,255,255;
    
    --red-90: #3d0511;
    --red-80: #65091c;
    --red-70: #970e29;
    --red-60: #c11235;
    --red-50: #dc143c;
    --red-40: #f05c6d;
    --red-30: #f8879d;
    --red-20: #f7a5b6;
    --red-15: #f9bcc8;
    --red-10: #fbd8df;
    --red-5: #fef1f3;
  
    /*
    --red-cool-90: #1e1517;
    --red-cool-80: #40282c;
    --red-cool-70: #68363f;
    --red-cool-60: #9e394b;
    --red-cool-50: #cd425b;
    --red-cool-40: #e16b80;
    --red-cool-30: #e09aa6;
    --red-cool-20: #ecbec6;
    --red-cool-10: #f3e1e4;
    --red-cool-5: #f8eff1;
    --red-warm-90: #1f1c18;
    --red-warm-80: #332d29;
    --red-warm-70: #524236;
    --red-warm-60: #805039;
    --red-warm-50: #c3512c;
    --red-warm-40: #d27a56;
    --red-warm-30: #dca081;
    --red-warm-20: #ecc0a7;
    --red-warm-10: #f4e3db;
    --red-warm-5: #f6efea;
    --red-vivid-80: #5c1111;
    --red-vivid-70: #8b0a03;
    --red-vivid-60: #b50909;
    --red-vivid-50: #e52207;
    --red-vivid-40: #fb5a47;
    --red-vivid-30: #ff8d7b;
    --red-vivid-20: #fdb8ae;
    --red-vivid-10: #fde0db;
    --red-vivid-5: #fff3f2;
    --red-cool-vivid-80: #4f1c24;
    --red-cool-vivid-70: #822133;
    --red-cool-vivid-60: #b21d38;
    --red-cool-vivid-50: #e41d3d;
    --red-cool-vivid-40: #f45d79;
    --red-cool-vivid-30: #fd8ba0;
    --red-cool-vivid-20: #f8b9c5;
    --red-cool-vivid-10: #f8dfe2;
    --red-cool-vivid-5: #fff2f5;
    --red-warm-vivid-80: #3e2a1e;
    --red-warm-vivid-70: #63340f;
    --red-warm-vivid-60: #9c3d10;
    --red-warm-vivid-50: #d54309;
    --red-warm-vivid-40: #ef5e25;
    --red-warm-vivid-30: #f39268;
    --red-warm-vivid-20: #f6bd9c;
    --red-warm-vivid-10: #fce1d4;
    --red-warm-vivid-5: #fff5ee;
    */
    --red-90-rgb: 61, 5, 17;
    --red-80-rgb: 101, 9, 28;
    --red-70-rgb: 151, 14, 41;
    --red-60-rgb: 193, 18, 53;
    --red-50-rgb: 220, 20, 60;
    --red-40-rgb: 240, 92, 109;
    --red-30-rgb: 248, 135, 157;
    --red-20-rgb: 247, 165, 182;
    --red-15-rgb: 249, 188, 200;
    --red-10-rgb: 251, 216, 223;
    --red-5-rgb: 254, 241, 243;
    /*
    --red-cool-90-rgb: 30,21,23;
    --red-cool-80-rgb: 64,40,44;
    --red-cool-70-rgb: 104,54,63;
    --red-cool-60-rgb: 158,57,75;
    --red-cool-50-rgb: 205,66,91;
    --red-cool-40-rgb: 225,107,128;
    --red-cool-30-rgb: 224,154,166;
    --red-cool-20-rgb: 236,190,198;
    --red-cool-10-rgb: 243,225,228;
    --red-cool-5-rgb: 248,239,241;
    --red-warm-90-rgb: 31,28,24;
    --red-warm-80-rgb: 51,45,41;
    --red-warm-70-rgb: 82,66,54;
    --red-warm-60-rgb: 128,80,57;
    --red-warm-50-rgb: 195,81,44;
    --red-warm-40-rgb: 210,122,86;
    --red-warm-30-rgb: 220,160,129;
    --red-warm-20-rgb: 236,192,167;
    --red-warm-10-rgb: 244,227,219;
    --red-warm-5-rgb: 246,239,234;
    --red-vivid-80-rgb: 92,17,17;
    --red-vivid-70-rgb: 139,10,3;
    --red-vivid-60-rgb: 181,9,9;
    --red-vivid-50-rgb: 229,34,7;
    --red-vivid-40-rgb: 251,90,71;
    --red-vivid-30-rgb: 255,141,123;
    --red-vivid-20-rgb: 253,184,174;
    --red-vivid-10-rgb: 253,224,219;
    --red-vivid-5-rgb: 255,243,242;
    --red-cool-vivid-80-rgb: 79,28,36;
    --red-cool-vivid-70-rgb: 130,33,51;
    --red-cool-vivid-60-rgb: 178,29,56;
    --red-cool-vivid-50-rgb: 228,29,61;
    --red-cool-vivid-40-rgb: 244,93,121;
    --red-cool-vivid-30-rgb: 253,139,160;
    --red-cool-vivid-20-rgb: 248,185,197;
    --red-cool-vivid-10-rgb: 248,223,226;
    --red-cool-vivid-5-rgb: 255,242,245;
    --red-warm-vivid-80-rgb: 62,42,30;
    --red-warm-vivid-70-rgb: 99,52,15;
    --red-warm-vivid-60-rgb: 156,61,16;
    --red-warm-vivid-50-rgb: 213,67,9;
    --red-warm-vivid-40-rgb: 239,94,37;
    --red-warm-vivid-30-rgb: 243,146,104;
    --red-warm-vivid-20-rgb: 246,189,156;
    --red-warm-vivid-10-rgb: 252,225,212;
    --red-warm-vivid-5-rgb: 255,245,238;
*/
    --violet-90: #201040;
    --violet-80: #3a1d74;
    --violet-70: #572cb1;
    --violet-60: #7143cf;
    --violet-50: #845cd6;
    --violet-40: #9370db;
    --violet-30: #b69ee7;
    --violet-20: #c5b3ec;
    --violet-15: #d3c5f0;
    --violet-10: #e5ddf6;
    --violet-5: #f5f2fc;

/*
    --violet-warm-90: #1b151b;
    --violet-warm-80: #382936;
    --violet-warm-70: #5c395a;
    --violet-warm-60: #864381;
    --violet-warm-50: #b04abd;
    --violet-warm-40: #bf77c8;
    --violet-warm-30: #d29ad8;
    --violet-warm-20: #e2bee4;
    --violet-warm-10: #f6dff8;
    --violet-warm-5: #f8f0f9;
    --violet-vivid-80: #39215e;
    --violet-vivid-70: #54278f;
    --violet-vivid-60: #783cb9;
    --violet-vivid-50: #9355dc;
    --violet-vivid-40: #ad79e9;
    --violet-vivid-30: #c39deb;
    --violet-vivid-20: #d5bfff;
    --violet-vivid-10: #ede3ff;
    --violet-vivid-5: #f7f2ff;
    --violet-warm-vivid-80: #481441;
    --violet-warm-vivid-70: #711e6c;
    --violet-warm-vivid-60: #93348c;
    --violet-warm-vivid-50: #be32d0;
    --violet-warm-vivid-40: #d85bef;
    --violet-warm-vivid-30: #ee83ff;
    --violet-warm-vivid-20: #f4b2ff;
    --violet-warm-vivid-10: #fbdcff;
    --violet-warm-vivid-5: #fef2ff;
    */
    --violet-90-rgb: 32, 16, 64;
    --violet-80-rgb: 58, 29, 116;
    --violet-70-rgb: 87, 44, 177;
    --violet-60-rgb: 113, 67, 207;
    --violet-50-rgb: 132, 92, 214;
    --violet-40-rgb: 147, 112, 219;
    --violet-30-rgb: 182, 158, 231;
    --violet-20-rgb: 197, 179, 236;
    --violet-15-rgb: 211, 197, 240;
    --violet-10-rgb: 229, 221, 246;
    --violet-5-rgb: 245, 242, 252;
    /*
    --violet-warm-90-rgb: 27,21,27;
    --violet-warm-80-rgb: 56,41,54;
    --violet-warm-70-rgb: 92,57,90;
    --violet-warm-60-rgb: 134,67,129;
    --violet-warm-50-rgb: 176,74,189;
    --violet-warm-40-rgb: 191,119,200;
    --violet-warm-30-rgb: 210,154,216;
    --violet-warm-20-rgb: 226,190,228;
    --violet-warm-10-rgb: 246,223,248;
    --violet-warm-5-rgb: 248,240,249;
    --violet-vivid-80-rgb: 57,33,94;
    --violet-vivid-70-rgb: 84,39,143;
    --violet-vivid-60-rgb: 120,60,185;
    --violet-vivid-50-rgb: 147,85,220;
    --violet-vivid-40-rgb: 173,121,233;
    --violet-vivid-30-rgb: 195,157,235;
    --violet-vivid-20-rgb: 213,191,255;
    --violet-vivid-10-rgb: 237,227,255;
    --violet-vivid-5-rgb: 247,242,255;
    --violet-warm-vivid-80-rgb: 72,20,65;
    --violet-warm-vivid-70-rgb: 113,30,108;
    --violet-warm-vivid-60-rgb: 147,52,140;
    --violet-warm-vivid-50-rgb: 190,50,208;
    --violet-warm-vivid-40-rgb: 216,91,239;
    --violet-warm-vivid-30-rgb: 238,131,255;
    --violet-warm-vivid-20-rgb: 244,178,255;
    --violet-warm-vivid-10-rgb: 251,220,255;
    --violet-warm-vivid-5-rgb: 254,242,255;
    */
    --yellow-90: #211905;
    --yellow-80: #3d2e09;
    --yellow-70: #5f470e;
    --yellow-30: #daa520;
    --yellow-60: #7b5d12;
    --yellow-50: #947016;
    --yellow-40: #bc8e1c;
    --yellow-20: #f5c000;
    --yellow-15: #ffd700;
    --yellow-10: #ffe975;
    --yellow-5: #fff8d1;
  
    /*
    --yellow-vivid-80: #422d19;
    --yellow-vivid-70: #5c4809;
    --yellow-vivid-60: #776017;
    --yellow-vivid-50: #947100;
    --yellow-vivid-40: #b38c00;
    --yellow-vivid-30: #ddaa01;
    --yellow-vivid-20: #ffcd07;
    --yellow-vivid-10: #fee685;
    --yellow-vivid-5: #fff5c2;
    */
    --yellow-90-rgb: 33, 25, 5;
    --yellow-80-rgb: 61, 46, 9;
    --yellow-70-rgb: 95, 71, 14;
    --yellow-60-rgb: 123, 93, 18;
    --yellow-50-rgb: 148, 112, 22;
    --yellow-40-rgb: 188, 142, 28;
    --yellow-30-rgb: 218, 165, 32;
    --yellow-20-rgb: 245, 192, 0;
    --yellow-15-rgb: 255, 215, 0;
    --yellow-10-rgb: 255, 233, 117;
    --yellow-5-rgb: 255, 248, 209;
    /*
    --yellow-vivid-80-rgb: 66,45,25;
    --yellow-vivid-70-rgb: 92,72,9;
    --yellow-vivid-60-rgb: 119,96,23;
    --yellow-vivid-50-rgb: 148,113,0;
    --yellow-vivid-40-rgb: 179,140,0;
    --yellow-vivid-30-rgb: 221,170,1;
    --yellow-vivid-20-rgb: 255,205,7;
    --yellow-vivid-10-rgb: 254,230,133;
    --yellow-vivid-5-rgb: 255,245,194;

*/
    --background: var(--color-white);
    /*
    --background-rgb: var(--background-light-rgb);
    --background-light: var(--pure-0);
    --background-light-rgb: var(--pure-0-rgb);
    --background-alternative: var(--gray-5);
    --background-alternative-rgb: var(--gray-5-rgb);
    */
    --background-dark: var(--color-neutral-base);
    --background-dark-rgb: var(--color-neutral-base-rgb);
    --border-color: var(--color-neutral-base);
     /*
    --border-color-alternative: var(--gray-40);
    --border-style: solid;
    --border-width: var(--surface-width-sm);
    */
    --color: var(--color-light);
    --color-rgb: var(--color-light-rgb);
    --color-light: var(--color-neutral-body);
    --color-light-rgb: var(--color-neutral-body-rgb);
    --color-dark: var(--color-white);
    --color-dark-rgb: var(--color-white-rgb);
    /*--interactive: var(--interactive-light);
    --interactive-rgb: var(--interactive-light-rgb);
    */
    --interactive-light: var(--color-primary-interactive-area);
    --interactive-light-rgb: var(--color-primary-interactive-area-rgb);
    --interactive-dark: var(--blue-20);
    --interactive-dark-rgb: var(--blue-20-rgb);
    /* --interactive-alternative: var(--color-primary-muted);
    --interactive-alternative-rgb: var(--color-primary-muted-rgb); */
    
    --info-base: var(--color-primary-surfaces);
    --info-base-rgb: var(--color-primary-surfaces-rgb);
    --info-dark: var(--color-primary-dark);
    --info-dark-rgb: var(--color-primary-dark-rgb);
    --info-background: var(--color-primary-background);
    --info-background-rgb: var(--color-primary-background-rgb);
    --info-alternative: var(--color-primary-background);
    --info-alternative-rgb: var(--color-primary-background-rgb);

    --success: var(--color-success-base);
    --success-rgb: var(--color-success-rgb);
    --success-alternative: var(--color-success-muted);
    --success-alternative-rgb: var(--color-success-muted-rgb);

    --warning: var(--color-warning-base);
    --warning-rgb: var(--color-warning-rgb);
    --warning-alternative: var(--color-warning-muted);
    --warning-alternative-rgb: var(--color-warning-muted-rgb);

    --danger: var(--color-danger-base);
    --danger-rgb: var(--color-danger-rgb);
    --danger-alternative: var(--color-danger-muted);
    --danger-alternative-rgb: var(--color-danger-muted-rgb);

    --font-family-base: 'Noto Sans', sans-serif;
    
    /* novo token */ --font-family-titles: 'Noto Sans Display', sans-serif;

    /* --surface-overlay-scrim: rgba(var(--rgb-secondary-09),var(--surface-opacity-md));
    --surface-overlay-text: linear-gradient(rgba(var(--rgb-secondary-01),var(--surface-opacity-none)),rgba(var(--rgb-secondary-09),var(--surface-opacity-default)));
    --visited: var(--visited-light);
    --visited-light: var(--blue-warm-vivid-80);
    --visited-dark: var(--gray-20);
    --disabled: var(--surface-opacity-md);
    --hover: var(--hover-light);
    --hover-light: var(--surface-opacity-xs);
    --hover-dark: var(--surface-opacity-sm);
    --hover-effect: linear-gradient(rgba(var(--interactive-rgb),var(--hover)),rgba(var(--interactive-rgb),var(--hover)));
    --pressed: var(--pressed-light);
    --pressed-light: var(--surface-opacity-md);
    --pressed-dark: var(--surface-opacity-lg);
    --pressed-effect: linear-gradient(rgba(var(--interactive-rgb),var(--pressed)),rgba(var(--interactive-rgb),var(--pressed)));
*/
    --focus-style: solid;
    --focus-color: var(--focus-color-light);
    --focus-color-light: var(--color-highlight-focus);
    --focus-color-dark: var(--color-highlight-muted);
    --focus: var(--focus-color);
    --focus-offset: 0;
    --focus-width: var(--surface-width-lg);

 /*   --active: var(--blue-warm-vivid-80);
    --active-rgb: var(--blue-warm-vivid-80-rgb);
    */
    --selected: var(--color-primary-interactive-area);
    /*
    --on: var(--blue-warm-vivid-40);
    --on-rgb: var(--blue-warm-vivid-40-rgb);
    --off: var(--gray-20);
    --off-rgb: var(--gray-20-rgb);
    --ondrag-border-color: var(--interactive);
    --ondrag-border-width: var(--surface-width-md);
    --ondrag-border-style: solid;
    --ondrag-shadow-offset-x: var(--surface-offset-none);
    --ondrag-shadow-offset-y: var(--surface-offset-md);
    --ondrag-shadow-blur: var(--surface-blur-lg);
    --ondrag-shadow-color: var(--interactive-rgb);
    --ondrag-shadow-opacity: var(--surface-opacity-sm);
    --ondrag-opacity: var(--surface-opacity-xl);
    --ondrag-rotate: -5deg;
    --ondrag-cursor: grabbing;
    --draggable-icon: grip-vertical;
    --draggable-cursor: grab;
    --dropzone-border-color: var(--interactive);
    --dropzone-border-width: var(--surface-width-sm);
    --dropzone-border-style: dashed;
    --ondrop-background: var(--interactive-rgb);
    --ondrop-opacity: var(--surface-opacity-sm);
    --ondrop-cursor: copy;
    --z-index-layer-0: 0;
    --z-index-layer-1: 1000;
    --z-index-layer-2: 2000;
    --z-index-layer-3: 3000;
    --z-index-layer-4: 4000;
    --color-primary-pastel-01: #c5d4eb;
    --color-primary-pastel-02: #dbe8fb;
    --color-primary-muteden-01: #2670e8;
    --color-primary-muteden-02: #5992ed;
    --color-primary-default: #1351b4;
    --color-primary-darken-01: #0c326f;
    --color-primary-darken-02: #071d41;
    --color-secondary-01: #fff;
    --color-secondary-02: #f8f8f8;
    --color-secondary-03: #ededed;
    --color-secondary-04: #ccc;
    --color-secondary-05: #9e9d9d;
    --color-secondary-06: #888;
    --color-secondary-07: #555;
    --color-secondary-08: #333;
    --color-secondary-09: #000;
    --color-highlight: #268744;
    --color-support-01: #36a191;
    --color-support-02: #f2e317;
    --color-support-03: #db4800;
    --color-support-04: #a26739;
    --color-support-05: #40e0d0;
    --color-support-06: #48cbeb;
    --color-support-07: #c72487;
    --color-support-08: #63007f;
    --color-support-09: #f08080;
    --color-support-10: #ff8c00;
    --color-support-11: #fdf5e6;
    --icon-size-base: 16px;
    --icon-size-xs: 8px;
    --icon-size-sm: 12px;
    --icon-size-lg: 20px;
    --icon-size-2x: 32px;
    --icon-size-3x: 48px;
    --icon-size-4x: 64px;
    --icon-size-5x: 80px;
    --icon-size-6x: 96px;
    --icon-size-7x: 112px;
    --icon-size-8x: 128px;
    --icon-size-9x: 144px;
    --icon-size-10x: 160px;
    --surface-width-none: 0;
    --surface-width-sm: 1px;
    --surface-width-md: 2px;
    --surface-width-lg: 4px;
    --surface-rounder-none: 0;
    --surface-rounder-sm: 4px;
    --surface-rounder-md: 8px;
    --surface-rounder-lg: 16px;
    --surface-rounder-pill: 999em;
    --surface-opacity-none: 0;
    --surface-opacity-default: 1;
    --surface-opacity-xs: 0.16;
    --surface-opacity-sm: 0.3;
    --surface-opacity-md: 0.45;
    --surface-opacity-lg: 0.65;
    --surface-opacity-xl: 0.85;
    --surface-border-solid-none: var(--surface-width-none) solid var(--color-secondary-06);
    --surface-border-solid-sm: var(--surface-width-sm) solid var(--color-secondary-06);
    --surface-border-solid-md: var(--surface-width-md) solid var(--color-secondary-06);
    --surface-border-solid-lg: var(--surface-width-lg) solid var(--color-secondary-06);
    --surface-border-dashed-none: var(--surface-width-none) dashed var(--color-secondary-06);
    --surface-border-dashed-sm: var(--surface-width-sm) dashed var(--color-secondary-06);
    --surface-border-dashed-md: var(--surface-width-md) dashed var(--color-secondary-06);
    --surface-border-dashed-lg: var(--surface-width-lg) dashed var(--color-secondary-06);
    --surface-blur-none: 0;
    --surface-blur-sm: 1px;
    --surface-blur-md: 3px;
    --surface-blur-lg: 6px;
    --surface-blur-xl: 9px;
    --surface-offset-none: 0;
    --surface-offset-sm: 1px;
    --surface-offset-md: 3px;
    --surface-offset-lg: 6px;
    --surface-offset-xl: 9px;
    --surface-offset-sm-n: -1px;
    --surface-offset-md-n: -3px;
    --surface-offset-lg-n: -6px;
    --surface-offset-xl-n: -9px;
    --surface-shadow-color: var(--rgb-secondary-09);
    --surface-shadow-none: none;
    --surface-shadow-sm: var(--surface-offset-none) var(--surface-offset-sm) var(--surface-blur-lg) rgba(var(--surface-shadow-color),var(--surface-opacity-xs));
    --surface-shadow-md: var(--surface-offset-none) var(--surface-offset-md) var(--surface-blur-lg) rgba(var(--surface-shadow-color),var(--surface-opacity-xs));
    --surface-shadow-lg: var(--surface-offset-none) var(--surface-offset-lg) var(--surface-blur-lg) rgba(var(--surface-shadow-color),var(--surface-opacity-xs));
    --surface-shadow-xl: var(--surface-offset-none) var(--surface-offset-xl) var(--surface-blur-lg) rgba(var(--surface-shadow-color),var(--surface-opacity-xs));
    --surface-shadow-sm-inset: var(--surface-offset-none) var(--surface-offset-sm-n) var(--surface-blur-lg) inset rgba(var(--surface-shadow-color),var(--surface-opacity-xs));
    --surface-shadow-md-inset: var(--surface-offset-none) var(--surface-offset-md-n) var(--surface-blur-lg) inset rgba(var(--surface-shadow-color),var(--surface-opacity-xs));
    --surface-shadow-lg-inset: var(--surface-offset-none) var(--surface-offset-lg-n) var(--surface-blur-lg) inset rgba(var(--surface-shadow-color),var(--surface-opacity-xs));
    --surface-shadow-xl-inset: var(--surface-offset-none) var(--surface-offset-xl-n) var(--surface-blur-lg) inset rgba(var(--surface-shadow-color),var(--surface-opacity-xs));
    --surface-shadow-sm-up: var(--surface-offset-none) var(--surface-offset-sm-n) var(--surface-blur-lg) rgba(var(--surface-shadow-color),var(--surface-opacity-xs));
    --surface-shadow-md-up: var(--surface-offset-none) var(--surface-offset-md-n) var(--surface-blur-lg) rgba(var(--surface-shadow-color),var(--surface-opacity-xs));
    --surface-shadow-lg-up: var(--surface-offset-none) var(--surface-offset-lg-n) var(--surface-blur-lg) rgba(var(--surface-shadow-color),var(--surface-opacity-xs));
    --surface-shadow-xl-up: var(--surface-offset-none) var(--surface-offset-xl-n) var(--surface-blur-lg) rgba(var(--surface-shadow-color),var(--surface-opacity-xs));
    --surface-shadow-sm-inset-up: var(--surface-offset-none) var(--surface-offset-sm) var(--surface-blur-lg) inset rgba(var(--surface-shadow-color),var(--surface-opacity-xs));
    --surface-shadow-md-inset-up: var(--surface-offset-none) var(--surface-offset-md) var(--surface-blur-lg) inset rgba(var(--surface-shadow-color),var(--surface-opacity-xs));
    --surface-shadow-lg-inset-up: var(--surface-offset-none) var(--surface-offset-lg) var(--surface-blur-lg) inset rgba(var(--surface-shadow-color),var(--surface-opacity-xs));
    --surface-shadow-xl-inset-up: var(--surface-offset-none) var(--surface-offset-xl) var(--surface-blur-lg) inset rgba(var(--surface-shadow-color),var(--surface-opacity-xs));
    --surface-shadow-sm-right: var(--surface-offset-sm) var(--surface-offset-none) var(--surface-blur-lg) rgba(var(--surface-shadow-color),var(--surface-opacity-xs));
    --surface-shadow-md-right: var(--surface-offset-md) var(--surface-offset-none) var(--surface-blur-lg) rgba(var(--surface-shadow-color),var(--surface-opacity-xs));
    --surface-shadow-lg-right: var(--surface-offset-lg) var(--surface-offset-none) var(--surface-blur-lg) rgba(var(--surface-shadow-color),var(--surface-opacity-xs));
    --surface-shadow-xl-right: var(--surface-offset-xl) var(--surface-offset-none) var(--surface-blur-lg) rgba(var(--surface-shadow-color),var(--surface-opacity-xs));
    --surface-shadow-sm-inset-right: var(--surface-offset-sm-n) var(--surface-offset-none) var(--surface-blur-lg) inset rgba(var(--surface-shadow-color),var(--surface-opacity-xs));
    --surface-shadow-md-inset-right: var(--surface-offset-md-n) var(--surface-offset-none) var(--surface-blur-lg) inset rgba(var(--surface-shadow-color),var(--surface-opacity-xs));
    --surface-shadow-lg-inset-right: var(--surface-offset-lg-n) var(--surface-offset-none) var(--surface-blur-lg) inset rgba(var(--surface-shadow-color),var(--surface-opacity-xs));
    --surface-shadow-xl-inset-right: var(--surface-offset-xl-n) var(--surface-offset-none) var(--surface-blur-lg) inset rgba(var(--surface-shadow-color),var(--surface-opacity-xs));
    --surface-shadow-sm-left: var(--surface-offset-sm-n) var(--surface-offset-none) var(--surface-blur-lg) rgba(var(--surface-shadow-color),var(--surface-opacity-xs));
    --surface-shadow-md-left: var(--surface-offset-md-n) var(--surface-offset-none) var(--surface-blur-lg) rgba(var(--surface-shadow-color),var(--surface-opacity-xs));
    --surface-shadow-lg-left: var(--surface-offset-lg-n) var(--surface-offset-none) var(--surface-blur-lg) rgba(var(--surface-shadow-color),var(--surface-opacity-xs));
    --surface-shadow-xl-left: var(--surface-offset-xl-n) var(--surface-offset-none) var(--surface-blur-lg) rgba(var(--surface-shadow-color),var(--surface-opacity-xs));
    --surface-shadow-sm-inset-left: var(--surface-offset-sm) var(--surface-offset-none) var(--surface-blur-lg) inset rgba(var(--surface-shadow-color),var(--surface-opacity-xs));
    --surface-shadow-md-inset-left: var(--surface-offset-md) var(--surface-offset-none) var(--surface-blur-lg) inset rgba(var(--surface-shadow-color),var(--surface-opacity-xs));
    --surface-shadow-lg-inset-left: var(--surface-offset-lg) var(--surface-offset-none) var(--surface-blur-lg) inset rgba(var(--surface-shadow-color),var(--surface-opacity-xs));
    --surface-shadow-xl-inset-left: var(--surface-offset-xl) var(--surface-offset-none) var(--surface-blur-lg) inset rgba(var(--surface-shadow-color),var(--surface-opacity-xs));
    --animation-ease: cubic-bezier(0.25,0.1,0.25,1);
    --animation-ease-in: cubic-bezier(0.42,0,1,1);
    --animation-ease-out: cubic-bezier(0,0,0.58,1);
    --animation-ease-in-out: cubic-bezier(0.42,0,0.58,1);
    --animation-ease-linear: cubic-bezier(0,0,1,1);
    --duration-very-fast: 0.1s;
    --duration-fast: 0.3s;
    --duration-moderate: 0.5s;
    --duration-slow: 0.8s;
    --duration-very-slow: 1s;
    */
    --font-weight-thin: 100;
    --font-weight-extra-light: 200;
    --font-weight-light: 300;
    --font-weight-regular: 400;
    --font-weight-medium: 500;
    --font-weight-semi-bold: 600;
    --font-weight-bold: 700;
    --font-weight-extra-bold: 800;
    --font-weight-black: 900;

    
    --font-size-scale-base: 16px;
    --font-size-scale-down-01: 0.875rem;
    --font-size-scale-down-02: 0.75em;
    --font-size-scale-down-03: 0.625rem;
    --font-size-scale-down-04: 0.5rem;
    --font-size-scale-up-01: 1.25rem;
    --font-size-scale-up-02: 1.75rem;
    --font-size-scale-up-03: 2rem;
    --font-size-scale-up-04: 2.5rem;
    --font-size-scale-up-05: 3rem;
    --font-size-scale-up-06: 3.5rem;

    --font-size-scale-up-07: 4rem;
    --font-size-scale-up-08: 4.5rem;
    --font-size-scale-up-09: 5rem;
    --font-size-scale-up-10: 5.5rem;
    --font-size-scale-up-11: 6rem;
    
    /*
    --font-line-height-low: 1.15;
    --font-line-height-medium: 1.45;
    --font-line-height-high: 1.85;
    --grid-breakpoint-xs: 0;
    --grid-breakpoint-xs-max-width: 100%;
    --grid-breakpoint-sm: 576px;
    --grid-breakpoint-sm-max-width: 536px;
    --grid-breakpoint-md: 992px;
    --grid-breakpoint-md-max-width: 952px;
    --grid-breakpoint-lg: 1280px;
    --grid-breakpoint-lg-max-width: 1200px;
    --grid-breakpoint-xl: 1600px;
    --grid-breakpoint-xl-max-width: 1560px;
    --grid-portrait-maxwidth: 100%;
    --grid-portrait-gutter: 16px;
    --grid-portrait-columns: 4;
    --grid-portrait-margin: 8px;
    --grid-tablet-maxwidth: 100%;
    --grid-tablet-gutter: 24px;
    --grid-tablet-columns: 8;
    --grid-tablet-margin: 40px;
    --grid-desktop-maxwidth: 1200px;
    --grid-desktop-gutter: 24px;
    --grid-desktop-columns: 12;
    --grid-desktop-margin: 40px;
    --grid-tv-maxwidth: 1520px;
    --grid-tv-gutter: 40px;
    --grid-tv-columns: 12;
    --grid-tv-margin: 40px;
    --spacing-vertical-top: top;
    --spacing-vertical-center: center;
    --spacing-vertical-bottom: bottom;
    --spacing-horizontal-left: left;
    --spacing-horizontal-center: center;
    --spacing-horizontal-right: right; */
    --spacing-scale-default: 0;
    --spacing-scale-half: .25rem;
    --spacing-scale-base: .5rem;
    --spacing-scale-baseh: .75rem;
    --spacing-scale-2x: 1rem;
    --spacing-scale-2xh: 1.25rem;
    --spacing-scale-3x: 1.5rem;
    --spacing-scale-3xh: 1.75rem;
    --spacing-scale-4x: 2rem;
    --spacing-scale-4xh: 2.25rem;
    --spacing-scale-5x: 2.5rem;
    --spacing-scale-5xh: 2.75rem;
    --spacing-scale-6x: 3rem;
    --spacing-scale-6xh: 3.25rem;
    --spacing-scale-7x: 3.5rem;
    --spacing-scale-7xh: 3.75rem;
    --spacing-scale-8x: 4rem;
    --spacing-scale-8xh: 4.25rem;
    --spacing-scale-9x: 4.5rem;
    --spacing-scale-9xh: 4.75rem;
    --spacing-scale-10x: 5rem;
    --spacing-scale-10xh: 5.25rem;
    /*
    --rgb-primary-pastel-01: 197,212,235;
    --rgb-primary-pastel-02: 219,232,251;
    --rgb-primary-lighten-01: 38,112,232;
    --rgb-primary-lighten-02: 89,146,237;
    --rgb-primary-default: 19,81,180;
    --rgb-primary-darken-01: 12,50,111;
    --rgb-primary-darken-02: 7,29,65;
    --rgb-secondary-01: 255,255,255;
    --rgb-secondary-02: 248,248,248;
    --rgb-secondary-03: 237,237,237;
    --rgb-secondary-04: 204,204,204;
    --rgb-secondary-05: 158,157,157;
    --rgb-secondary-06: 136,136,136;
    --rgb-secondary-07: 85,85,85;
    --rgb-secondary-08: 51,51,51;
    --rgb-secondary-09: 0,0,0;
    --rgb-highlight: 38,135,68;
    --rgb-support-01: 54,161,145;
    --rgb-support-02: 242,227,23;
    --rgb-support-03: 219,72,0;
    --rgb-support-04: 162,103,57;
    --rgb-support-05: 64,224,208;
    --rgb-support-06: 72,203,235;
    --rgb-support-07: 199,36,135;
    --rgb-support-08: 99,0,127;
    --rgb-support-09: 240,128,128;
    --rgb-support-10: 255,140,0;
    --rgb-support-11: 253,245,230; */

    /* tokens procempa */
    --color-neutral-body: var(--gray-90);
    --color-neutral-body-rgb: var(--gray-90-rgb);
    --color-neutral-dark: var(--gray-60);
    --color-neutral-dark-rgb: var(--gray-60-rgb);
    --color-neutral-base: var(--gray-50);
    --color-neutral-base-rgb: var(--gray-50-rgb);
    --color-neutral-muted: var(--gray-30);
    --color-neutral-muted-rgb: var(--gray-30-rgb);
    --color-neutral-light: var(--gray-15);
    --color-neutral-light-rgb: var(--gray-15-rgb);
    --color-neutral-background: var(--gray-5);
    --color-neutral-background-rgb: var(--gray-5-rgb);
    --color-white: var(--pure-0);
    --color-white-rgb: var(--pure-0-rgb);

    --color-primary-headings: var(--blue-80);
    --color-primary-headings-rgb: var(--blue-80-rgb);
    --color-primary-dark: var(--blue-70);
    --color-primary-dark-rgb: var(--blue-70-rgb);
    --color-primary-interactive: var(--blue-60);
    --color-primary-interactive-rgb: var(--blue-60-rgb);
    --color-primary-interactive-area: var(--blue-50);
    --color-primary-interactive-area-rgb: var(--blue-50-rgb);
    --color-primary-surfaces: var(--blue-40);
    --color-primary-surfaces-rgb: var(--blue-40-rgb);
    --color-primary-muted: var(--blue-30);
    --color-primary-muted-rgb: var(--blue-30-rgb);
    --color-primary-background: var(--blue-5);
    --color-primary-background-rgb: var(--blue-5-rgb);

    --color-highlight-dark: var(--orange-60);
    --color-highlight-dark-rgb: var(--orange-60-rgb);
    --color-highlight-focus: var(--orange-30);
    --color-highlight-focus-rgb: var(--orange-30-rgb);
    --color-highlight-muted: var(--orange-15);
    --color-highlight-muted-rgb: var(--orange-15-rgb);

    --color-danger-dark: var(--red-70);
    --color-danger-dark-rgb: var(--red-70-rgb);
    --color-danger-base: var(--red-50);
    --color-danger-base-rgb: var(--red-50-rgb);
    --color-danger-muted: var(--red-20);
    --color-danger-muted-rgb: var(--red-20-rgb);
    --color-danger-background: var(--red-5);
    --color-danger-background-rgb: var(--red-5-rgb);

    --color-success-dark: var(--green-70);
    --color-success-dark-rgb: var(--green-70-rgb);
    --color-success-base: var(--green-50);
    --color-success-base-rgb: var(--green-50-rgb);
    --color-success-muted: var(--green-20);
    --color-success-muted-rgb: var(--green-20-rgb);
    --color-success-background: var(--green-5);
    --color-success-background-rgb: var(--green-5-rgb);

    --color-warning-dark: var(--yellow-50);
    --color-warning-dark-rgb: var(--yellow-50-rgb);
    --color-warning-base: var(--yellow-20);
    --color-warning-base-rgb: var(--yellow-20-rgb);
    --color-warning-muted: var(--yellow-10);
    --color-warning-muted-rgb: var(--yellow-10-rgb);
    --color-warning-background: var(--yellow-5);
    --color-warning-background-rgb: var(--yellow-5-rgb);

}