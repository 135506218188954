// Padrão de variáveis: $função-variável

// Neutros: Textos e fundos neutros
$neutral-darker: var(--color-neutral-body); //Uso: body tex
$neutral-dark: var(--color-neutral-dark);
$neutral: var(--color-neutral-base);
$neutral-medium-light:var(--color-neutral-muted); //Uso: muted
$neutral-light: var(--color-neutral-light); //Uso: border
$neutral-lighter: var(--color-neutral-background); //Uso: background

// Primary: Interação, selecionados, marca
$primary-darker: var(--color-primary-headings);
$primary-dark: var(--color-primary-dark);
$primary: #457EE7; // Uso: link
$primary-background: var(--color-primary-surfaces);
$primary-light: var(--color-primary-muted); // Uso: muted, disabled
$primary-lighter: var(--color-primary-background); // Uso: select, hover

// Secondary: Destaque e decoração
$secondary-dark: var(--color-highlight-dark);
$secondary-highlight: var(--color-highlight-focus); // Uso: focus, higliht
$secondary-light: var(--color-highlight-muted); // Uso: focus, higliht

// Danger: Ações destrutivas e irreversívei
$danger-dark: var(--color-danger-dark);
$danger: #DC143C;
$danger-light: var(--color-danger-muted); // Uso: muted, disabled

// Success: Ações bem-sucedidas
$success-dark: #3A623A;
$success: #64A364;
$success-light: #9EC69E; // Uso: background

$custom-colors: (
  "neutral-darker": $neutral-darker,
  "neutral-dark": $neutral-dark,
  "neutral": $neutral,
  "neutral-medium-light": $neutral-medium-light,
  "neutral-light": $neutral-light,
  "neutral-lighter": $neutral-lighter,

  "primary-darker": $primary-darker,
  "primary-dark": $primary-dark,
  "primary": $primary,
  "primary-background": $primary-background,
  "primary-light": $primary-light,
  "primary-lighter": $primary-lighter,

  "secondary-dark": $secondary-dark,
  "secondary-highlight": $secondary-highlight,
  "secondary-light": $secondary-light,

  "danger-dark": $danger-dark,
  "danger": $danger,
  "danger-light": $danger-light,

  "success-dark": $success-dark,
  "success": $success,
  "success-light": $success-light
);
