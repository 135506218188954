
@each $color, $value in $custom-colors {
  .bg-#{$color} {
    background-color: $value;
  }
}

@each $color, $value in $custom-colors {
  .text-#{$color} {
    color: $value;
  }
}

@each $color, $value in $custom-colors {
  .border-#{$color} {
    border-color: $value;
    --bs-border-color: #{$value};
  }
}

/*
.text-neutral-dark {
  color: #4E576D;
};
*/

@include media-breakpoint-up(sm) {
  .sm-checkbox-align-form {
    margin-top: 2.75rem;
  }
}

@include media-breakpoint-up(md) {
  .md-checkbox-align-form {
    margin-top: 2.75rem;
  }
}